import React, { Fragment, useEffect, useState } from "react";
import CheckoutAddressCart from "./CheckoutAddressCart";
import CheckoutLoginCart from "./CheckoutLoginCart";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const CheckoutCart = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [address, setAddress] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch login status and address from localStorage
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    const storedAddress = JSON.parse(localStorage.getItem("address") || "{}");
    localStorage.setItem("dataComingFrom", "add-to-cart");
    setIsLoggedIn(loggedInStatus);
    setAddress(storedAddress);
    const isAddressEmpty = (address) => {
      return !address || Object.keys(address).length === 0;
    };
    // Redirect logic

    if (loggedInStatus && !isAddressEmpty(storedAddress)) {
      navigate("/payment"); // Redirect to payment if logged in and address exists
    } else if (loggedInStatus) {
      navigate("/product-checkoutAddress"); // Redirect to address page if logged in but no address
    } else {
      navigate("/checkoutLoginCart"); // Redirect to login if not logged in
    }
  }, [navigate]);

  return (
    <Fragment>
      <Helmet>
        <title>Checkout | D'decor Rugs</title>
        <meta property="og:title" content="Checkout | D'decor Rugs" />
      </Helmet>
    </Fragment>
  );
};

export default CheckoutCart;
