import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchShopData } from "../../../store/shop-actions";
import { useNavigate } from "react-router-dom";
const rugsList = [
  {
    slug: "new-arrivals",
    name: "New Arrivals",
    metaTitle: "New Arrivals",
    metaDescription: "New Arrival Rugs",
  },
  {
    slug: "best-sellers",
    name: "Best Sellers",
    metaDescription: "Best Seller Rugs",
  },
  {
    slug: "collection",
    name: "Rugs by Collection",
    metaDescription: "Rugs By Collection",
  },
  { slug: "colors", name: "Rugs by Colors", metaDescription: "Rugs By Colors" },
  { slug: "rugs", name: "All Rugs", metaDescription: "New Arrival Rugs" },
];
const DropDownShop = ({ onMouseEnter, onMouseLeave, data }) => {
  const dispatch = useDispatch();
  const shopData = useSelector((state) => state.shops.shopData);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchShopData());
  }, [dispatch]);

  const handleFilterClick = (filterType, valueSlug, displayValue) => {
    const urlParams = new URLSearchParams();
    urlParams.set(filterType, valueSlug);
    localStorage.setItem("Filter", displayValue);
    // urlParams.set("page", 1); // Set initial page
    // urlParams.set("limit", 12); // Set items per page limit

    navigate(`/shop?${urlParams.toString()}`, {
      state: { filterType, displayValue },
    });

    onMouseLeave();
  };
  const handleItemClick = (item, title) => {
    // Save the clicked item data to localStorage as selectedItem
    const selectedItem = { ...item, title: title };
    if (item.slug !== "rugs") {
      localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
    }
    if (item.slug === "rugs") {
      localStorage.removeItem("selectedItem");
    }
    window.location.href = `/${item.slug}`;
    // The onMouseLeave function will be called to close the dropdown
    onMouseLeave();
  };
  return (
    <Fragment>
      <div
        className="dropDownContainer"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="rugsList">
          <ul>
            <li className="rugsHeader">RUGS</li>
            {rugsList.map((rug) => (
              <li key={rug.slug} onClick={() => handleItemClick(rug)}>
                {rug.name}
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="rugs-divider"></div> */}
        {data.map((item) => (
          <div className="sizeList" key={item.title}>
            <ul>
              <li className="sizeHeader">{item.title}</li>
              {item.title === "Color"
                ? item.dataCollection?.map((item) => (
                    <li key={item.slug} onClick={() => handleItemClick(item)}>
                      {/* <a
                        href={`/${item.slug}`}
                        onClick={() => handleItemClick(item)}
                      > */}
                      <span
                        className="colorCircle"
                        style={{
                          backgroundColor: item.name,
                          display: "inline-block",
                          width: "15px",
                          height: "15px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                      ></span>
                      {item.name}
                      {/* </a> */}
                    </li>
                  ))
                : item.dataCollection?.map((itemInner) => (
                    <li
                      key={itemInner.slug}
                      onClick={() => handleItemClick(itemInner, item.title)}
                    >
                      {" "}
                      {/* <a
                        href={`/${item.slug}`}
                        onClick={() => handleItemClick(item)}
                      > */}
                      {itemInner.name} {/* </a> */}
                    </li>
                  ))}
            </ul>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default DropDownShop;
