import React, { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../assets/css/TrackOrder.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Helmet } from "react-helmet";
import Loader from "../Common/Loader";
import axios from "axios";
import { baseURL } from "../../utils";
const TrackOrder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const handleRedirect = (item) => {
    window.location.href = item;
  };

  const validationSchema = Yup.object({
    orderNo: Yup.string().required("Order ID is required"),
    billingEmail: Yup.string()
      .email("Invalid email address")
      .required("Billing Email is required"),
  });

  const initialValues = {
    orderNo: "",
    billingEmail: "",
  };

  const handleSubmit = async (values) => {
    const body = {
      email: values.billingEmail,
      orderNo: values.orderNo,
    };
    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/order/status`, body);
      if (response.data.status === true) {
        window.location.href = `/order-status/${values.billingEmail}/${values.orderNo}`;
      } else {
        setIsError(true);
        setError(response.data.message);
      }
    } catch (error) {
      setIsError(true);
      setError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Track Your Order| D'decor Rugs</title>
        <meta property="og:title" content="Track Your Order | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="intro-track-container">
        <div className="heading-track">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            -{" "}
            <span
              onClick={() => handleRedirect("/rugs")}
              style={{ cursor: "pointer" }}
            >
              Shop -{" "}
            </span>
            <span>Track my Order</span>
          </h2>
        </div>
      </div>
      <div className="track-order-container">
        <h1>Track Your Order</h1>
        <p>
          To track your order, please enter your Order No in the box below and
          press the "Track" button. This was given to you on your receipt and in
          the confirmation email you should have received.
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="track-order-form">
              <div className="form-group">
                <label htmlFor="order-id">Order No</label>
                <Field
                  type="text"
                  id="order-id"
                  name="orderNo"
                  placeholder="Found in your order confirmation email."
                />
                <ErrorMessage
                  name="orderNo"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-group">
                <label htmlFor="billing-email">Billing Email</label>
                <Field
                  type="email"
                  id="billing-email"
                  name="billingEmail"
                  placeholder="Email you used during checkout."
                />
                <ErrorMessage
                  name="billingEmail"
                  component="div"
                  className="error-message"
                />
              </div>
              <button type="submit">{loading ? <Loader /> : "TRACK"}</button>
            </Form>
          )}
        </Formik>
        {isError && (
          <div style={{ marginTop: "5px" }} className="error-message">
            {error}
          </div>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};

export default TrackOrder;
