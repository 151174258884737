import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Brochure.scss";
import { MdOutlineFileDownload } from "react-icons/md";

// Images
import brochure1 from "../../assets/images/coll1.png";
import brochure2 from "../../assets/images/brochure2.png";
import brochure3 from "../../assets/images/brochure3.png";
import brochure4 from "../../assets/images/brochure4.png";
import brochure5 from "../../assets/images/brochure5.png";
import brochure6 from "../../assets/images/brochure6.png";
import brochure7 from "../../assets/images/brochure7.png";
import brochure8 from "../../assets/images/brochure8.png";
import { fetchBrochure } from "../../utils";
import { Helmet } from "react-helmet";
const brochureCardData = [
  {
    image: brochure1,
    name: "Burlington",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798065518-Rugs+Burlington+Catalogue+2024+July.pdf",
  },
  {
    image: brochure2,
    name: "Montage",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798096511-Rugs+Catalogue+-+2024+2.pdf",
  },
  {
    image: brochure8,
    name: "Harmony Vol 1",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798005249-RUGS+-+Harmony+-+Vol_1.pdf",
  },
  {
    image: brochure3,
    name: "Harmony Vol 2",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798040182-RUGS+-+Harmony+-+Vol_2.pdf",
  },
  {
    image: brochure4,
    name: "Essence",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798116211-Rugs-Essence-Catalogue-2022.pdf",
  },
  {
    image: brochure5,
    name: "Provence",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798188075-Upload_20230703-094800.pdf",
  },
  {
    image: brochure6,
    name: "Timeless",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798165540-Rugs-Timeless-Catalogue-2022.pdf",
  },
  {
    image: brochure7,
    name: "Opulence",
    filePath:
      "https://ddecor-rugs.s3.ap-south-1.amazonaws.com/1732798142045-Rugs-Opulence-Catalogue-2022.pdf",
  },
];

const Brochure = () => {
  const [brochures, setBrochures] = useState([]);
  useEffect(() => {
    const fetchBrochureData = async () => {
      const response = await fetchBrochure();
      setBrochures(response);
    };
    fetchBrochureData();
  }, []);
  const handleFileDownload = (filePath) => {
    if (!filePath) return;
    try {
      window.open(filePath);
    } catch (error) {
      console.error("Error loading file:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Brochure | D'decor Rugs</title>
        <meta property="og:title" content="Brochure | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="brochure-page-container">
        <div className="brochure-page-main-content">
          <div className="all-brochure-cards-box">
            {brochures.map((item, index) => (
              <div
                className="brochure-main-card-details"
                key={index}
                onClick={() => handleFileDownload(item.pdf.url)}
              >
                <div className="brochure-card-image">
                  <div className="brochure-image">
                    <img src={item.image.url} alt="Brochure" />
                  </div>
                  <div className="download-overlay">
                    <div className="brochure-download">
                      <MdOutlineFileDownload className="download-icon" />
                    </div>
                  </div>
                </div>
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Brochure;
