import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Password.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../utils";
import Loader from "../Common/Loader";
import { Helmet } from "react-helmet";
const Password = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address") // Validation for email format
      .required("Email is required"), // Required field validation
  });
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const requestData = {
        ...values,
        origin: window.location.origin, // Add origin to the body
      };
      const response = await axios.post(
        `${baseURL}/auth/forget-password`,
        requestData
      );
      if (response.data.success === true) {
        // Success: Show the message and navigate
        setSnackbarMessage(
          "Reset link sent successfully! Please check your email inbox."
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          resetForm(); // Optionally reset the form
          // navigate("/"); // Navigate after 2 seconds
        }, 2000);
      } else {
        // If there is an error message in the response
        setSnackbarMessage(
          response.data.message || "Failed to send reset link."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      // Handle server or network errors
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  return (
    <>
      <Helmet>
        <title>Forgot Password| D'decor Rugs</title>
        <meta property="og:title" content="Forgot Password | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="login-page-container">
        <div className="header-of-login">
          <p>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            -{" "}
            <span
              onClick={() => handleRedirect("/login")}
              style={{ cursor: "pointer" }}
            >
              Login
            </span>{" "}
            - <span>Forgot Password</span>
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            -{" "}
            <span
              onClick={() => handleRedirect("/login")}
              style={{ cursor: "pointer" }}
            >
              Login
            </span>{" "}
            - <span>Forgot Password</span>
          </p>
        </div>
        <div className="password-page-container">
          <div className="password-main-content">
            <h4>FORGOT PASSWORD</h4>
            <p>
              Forgot password? Please enter your email address. You will receive
              a link to create a new password via email.
            </p>

            <Formik
              initialValues={{ email: "" }} // Initial value for the email field
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  {/* Email input field */}
                  <Field
                    type="text"
                    name="email"
                    // placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                  {/* Display validation message */}

                  <button type="submit">SEND</button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Password;
