import React, { useEffect, useState } from "react";
import "../../assets/css/Footer.scss";
import logo from "../../assets/images/logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import wtsp from "../../assets/images/whatsapp.png";
import HomeMobile from "../Sections/Footer/HomeMobile";
import Dropdown from "../Sections/Footer/Dropdown";
import axios from "axios";
import { baseURL } from "../../utils";
import { Snackbar, Alert } from "@mui/material";
import DropDownShopFooter from "../Sections/Footer/DropDownShopFooter";
const Footer = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [messageColor, setMessageColor] = useState("green");
  const [message, setMessage] = useState("");
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 100);
      setShowNavbar(window.scrollY > 100); // Show navbar when scrolling past 100px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const currentScrollY = window.scrollY + window.innerHeight;

    // Show the button if the user is near the bottom of the page
    if (currentScrollY >= scrollHeight - 100) {
      setShowScrollButton(true);
    } else if (window.scrollY < lastScrollY) {
      // If scrolling up after scrolling down 100px, show the button
      setIsScrollingUp(true);
      setShowScrollButton(window.scrollY > 100 && isScrollingUp);
    } else {
      setIsScrollingUp(false);
      setShowScrollButton(false);
    }

    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY, isScrollingUp]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to handle newsletter subscription

  // Data arrays for footer links
  const shopData = [
    {
      slug: "new-arrivals",
      name: "New Arrivals",
      metaTitle: "New Arrivals",
      metaDescription: "New Arrival Rugs",
    },
    {
      slug: "best-sellers",
      name: "Best Sellers",
      metaTitle: "Best Sellers",
      metaDescription: "Best Seller Rugs",
    },
    {
      slug: "collection",
      name: "Rugs by Collection",
      metaTitle: "Rugs by Collection",
      metaDescription: "Rugs By Collection",
    },
    {
      slug: "colors",
      name: "Rugs by Colors",
      metaTitle: "Rugs by Colors",
      metaDescription: "Rugs By Colors",
    },
    {
      slug: "rugs",
      name: "All Rugs",
      metaTitle: "All Rugs",
      metaDescription: "New Arrival Rugs",
    },
  ];

  const ourCompanyData = [
    { title: "About Us", link: "/about-us" },
    { title: "Store Locator", link: "/store-locator" },
    { title: "Quality Standards", link: "/quality-standards" },
    { title: "Blog", link: "/blog" },
    { title: "Media", link: "/media" },
    { title: "Contact Us", link: "/contact-us" },
  ];

  const customerData = [
    { title: "Track Your Order", link: "/track-order" },
    { title: "FAQs", link: "/faqs" },
    { title: "Brochure", link: "/brochure" },
    { title: "Returns & Refunds", link: "/returns-refunds" },
    { title: "Terms & Conditions", link: "/terms-and-conditions" },
    { title: "Privacy Policy", link: "/privacy-policy" },
  ];

  const handleItemClick = (item) => {
    if (item.slug !== "rugs") {
      localStorage.setItem("selectedItem", JSON.stringify(item));
    }
    window.location.href = `/${item.slug}`;
  };
  const handleEmailChange = (e) => {
    setMessage("");
    setSubscriptionMessage("");
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }
    try {
      const response = await axios.post(`${baseURL}/newsletter`, {
        email: email,
      });
      const result = response.data;
      if (result.success === true) {
        setSubscriptionMessage("Thank you for subscribing!"); // Success message
        setMessageColor("green");
        setMessage(""); // Clear error message if subscription is successful
        setEmail("");
      } else {
        setMessageColor("red");
        setSubscriptionMessage(
          result.message || "Something went wrong. Please try again."
        ); // Clear any success message
        setMessage("");
      }
    } catch (error) {
      setSubscriptionMessage(""); // Clear any success message
      setMessage("Something went wrong. Please try again.");
    }
  };
  // Component to render list of links for each footer section
  const FooterLinkSection = ({ title, data }) => (
    <div className="all-links-of-footer">
      <h5>{title}</h5>
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <a href={item.link}>{item.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );

  const FooterLinkShopSection = ({ data }) => (
    <div className="all-links-of-footer">
      <h5>SHOP</h5>
      <ul>
        {data.map((item, index) => (
          <li
            key={index}
            onClick={() => handleItemClick(item)}
            style={{
              cursor: "pointer",
            }}
          >
            <a>{item.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
  const openSocialLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const socialLinks = [
    { Icon: FaFacebookF, url: "https://www.facebook.com/Ddecorrugs" },
    {
      Icon: FaInstagram,
      url: "https://www.instagram.com/ddecorrugs",
    },
    {
      Icon: FaLinkedinIn,
      url: "https://in.linkedin.com/company/d'decor-exports-pvt-ltd",
    },
    { Icon: FaYoutube, url: "https://www.youtube.com/@ddecor" },
  ];
  const handleWhatsapp = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=+917718801634&text=Hello!",
      "_blank"
    );
  };
  return (
    <>
      <div className="footer-container-page">
        <div className="footer-main-content">
          <div className="footer-left-box">
            <div className="footer-logo">
              <img src={logo} alt="Company Logo" />
            </div>
            <div className="heading-email-icons-box">
              <h6>NEWSLETTER</h6>
              <div>
                <input
                  type="text"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                />
                {message && <p className="message-subscribe">{message}</p>}
                <button onClick={handleSubscribe}>SUBSCRIBE</button>
                {subscriptionMessage && (
                  <p
                    className="message-subscribe"
                    style={{ color: messageColor }}
                  >
                    {subscriptionMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="footer-right-box">
            <FooterLinkShopSection title="SHOP" data={shopData} />
            <FooterLinkSection title="OUR COMPANY" data={ourCompanyData} />
            <FooterLinkSection title="CUSTOMER SERVICE" data={customerData} />
          </div>
        </div>
        <div className="copy-right-box">
          <p>Connect with us</p>
          <div className="copy-right-box-content">
            <div className="left-box-icons">
              {socialLinks.map(({ Icon, url }, index) => (
                <div
                  key={index}
                  className="social-link-icons"
                  onClick={() => openSocialLink(url)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => e.key === "Enter" && openSocialLink(url)}
                >
                  <Icon />
                </div>
              ))}
              {/* <FaFacebookF />

              <div className="social-link-icons">
                <FaInstagram />
              </div>
              <div className="social-link-icons">
                <FaLinkedinIn />
              </div>
              <div className="social-link-icons">
                <FaYoutube />
              </div> */}
            </div>
            {/* <div className="copy-right-content">
              <p>COPYRIGHT 2024 D’DECOR</p>
            </div> */}
            <div className="copy-right-content">
              <p>COPYRIGHT {new Date().getFullYear()} D’DECOR</p>
            </div>
          </div>
        </div>
        <div className="whatsapp-icon" onClick={handleWhatsapp}>
          <img src={wtsp} alt="WhatsApp" className="whatsapp-image" />
        </div>
        {/* WhatsApp icon and scroll-to-top button */}

        <div
          className="up-arrow-button"
          style={{ display: showScrollButton ? "flex" : "none" }}
        >
          <button onClick={scrollToTop}>
            <svg
              width="32"
              height="12"
              viewBox="0 0 32 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.366 10.6099L16.4099 0.67334L0.634277 10.6099"
                stroke="#636262"
                strokeWidth="1.02439"
              />
            </svg>
          </button>
        </div>

        {/* Mobile device view section start */}
        <div className="mobile-device-view">
          <div className="content-of-mobile-device">
            <img src={logo} />
            <div className="left-box-icons">
              {socialLinks.map(({ Icon, url }, index) => (
                <div
                  key={index}
                  className="social-link-icons"
                  onClick={() => openSocialLink(url)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => e.key === "Enter" && openSocialLink(url)}
                >
                  <Icon />
                </div>
              ))}
            </div>
            <h5>NEWSLETTER</h5>
            <div className="email-button">
              <input
                type="text"
                placeholder="E-mail"
                value={email}
                onChange={(e) => handleEmailChange(e)}
              />
              <button onClick={handleSubscribe}>SUBSCRIBE</button>
            </div>
            {message && <p className="message-subscribe">{message}</p>}
            {subscriptionMessage && (
              <p className="message-subscribe" style={{ color: messageColor }}>
                {subscriptionMessage}
              </p>
            )}
            <div className="mobile-footer-container">
              <DropDownShopFooter title="SHOP" data={shopData} />
              <Dropdown title="OUR COMPANY" data={ourCompanyData} />
              <Dropdown title="CUSTOMER SERVICE" data={customerData} />
            </div>
            <div className="copy-right-content">
              <div className="center-content">
                <p>COPYRIGHT {new Date().getFullYear()} D’DECOR</p>
              </div>
              {/* <div className="right-content" onClick={handleWhatsapp}>
                <img src={wtsp} className="wtsp-icon" />
              </div> */}
            </div>
          </div>
        </div>
        {/* Mobile device view section end */}
      </div>

      <div className="navbar fixed-navbar">
        <HomeMobile />
      </div>
    </>
  );
};

export default Footer;
