import React from "react";
import "../../../assets/css/PrintAds.scss";
import printone from "../../../assets/images/printone.png";
import printtwo from "../../../assets/images/printtwo.png";
import printthree from "../../../assets/images/printthree.png";
import printfour from "../../../assets/images/printfour.png";
import printfive from "../../../assets/images/printfive.png";

const PrintAds = () => {
  const newsData = [
    {
      title: "Curtain-maker D’Decor to in..",
      date: "March 22, 2024",
      image: printone,
      link: "https://www.livemint.com/companies/curtainmaker-d-decor-to-increase-manufacturing-capacity-by-50-in-four-years-11711100123295.html",
    },
    {
      title: "Sustainable elegance: Sanjana..",
      date: "August 3, 2024",
      image: printtwo,
      link: "https://elledecor.in/article/d-decor-sansaar-sarah-arora-sanjana-arora/",
    },
    {
      title: "Sansaar, D'Decor's conscious..",
      date: "October 25, 2024",
      image: printthree,
      link: "https://www.ptinews.com/story/business/sansaar-d-decor-s-conscious-fabric-brand-unveils-a-new-nationwide-tvc-with-brand-ambassador-ranveer-singh/1683351",
    },
    {
      title: "D'Decor to expand manufact..",
      date: "October 11, 2023",
      image: printfour,
      link: "https://retail.economictimes.indiatimes.com/news/home-and-decor/ddecor-to-expand-manufacturing-and-warehousing-capacity/104342757",
    },
    {
      title: "Decoding D' Decor's business..",
      date: "February 26, 2024",
      image: printfive,
      link: "https://www.storyboard18.com/brand-makers/decoding-d-decors-business-strategy-24475.htm",
    },
  ];
  const handleNewsArticle = (item) => {
    window.open(item.link, "_blank");
  };
  return (
    <div className="in-the-news-container">
      <div className="in-the-news-articles">
        {newsData.map((news) => (
          <div
            className="in-the-news-item"
            onClick={() => handleNewsArticle(news)}
          >
            <div className="news-image">
              <img src={news.image} alt={news.title} />
            </div>
            <div className="in-the-news-divider"></div>
            <div className="news-content">
              <div className="news-content-title">
                <p>{news.title}</p>
              </div>
              <div className="news-content-date">
                <p>{news.date}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrintAds;
