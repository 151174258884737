import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/Pages/Home";
import Product from "./components/Pages/Product";
import ProductDetails from "./components/Pages/ProductDetails";
import Login from "./components/Pages/Login";
import Singup from "./components/Pages/Singup";
import Cart from "./components/Pages/Cart";
import Checkout from "./components/Pages/Checkout";
import Payment from "./components/Pages/Payment";
import CheckoutAddress from "./components/Pages/CheckoutAddress";
import StoreLocator from "./components/Pages/StoreLocator";
import QualityStandards from "./components/Pages/QualityStandards";
import AboutUs from "./components/Pages/AboutUs";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import Thankyou from "./components/Pages/Thankyou";
import CheckoutCart from "./components/Pages/CheckoutCart";
import CheckoutAddressCart from "./components/Pages/CheckoutAddressCart";
import PaymentCart from "./components/Pages/PaymentCart";
import Profile from "./components/Pages/Profile";
import Media from "./components/Pages/Media";
import Collection from "./components/Pages/Collection";
import Password from "./components/Pages/Password";
import TrackOrder from "./components/Pages/TrackOrder";
import Refund from "./components/Pages/Refund";
import FAQ from "./components/Pages/FAQ";
import { Blog } from "./components/Pages/Blog";
import BlogInner from "./components/Pages/BlogInner";
import CheckoutSignup from "./components/Pages/CheckoutSignup";
import TermsAndCondition from "./components/Pages/TermsAndCondition";
import SearchedProducts from "./components/Pages/SearchedProducts";
import Brochure from "./components/Pages/Brochure";
import GuestCheckout from "./components/Pages/GuestCheckout";
import ContactUs from "./components/Pages/ContactUs";
import CheckoutSignUpCart from "./components/Pages/CheckoutSignUpCart";
import GuestCheckoutCart from "./components/Pages/GuestCheckoutCart";
import NewPassword from "./components/Pages/NewPassword";
import BlogInnerTwo from "./components/Pages/BlogInnerTwo";
import TradeProgram from "./components/Pages/TradeProgram";
import PaymentPage from "./components/Pages/TestButton";
import Transaction from "./components/Pages/Transaction";
import BlogInnerThree from "./components/Pages/BlogInnerThree";
import CheckoutLogin from "./components/Pages/CheckoutLogin";
import CheckoutLoginCart from "./components/Pages/CheckoutLoginCart";
import { useSelector } from "react-redux";
import OrderStatus from "./components/Pages/OrderStatus";
function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // useEffect(() => {
  //   setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
  // }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/shop" element={<Product />} />
      <Route path="/collection" element={<Product />} />
      <Route path="/category" element={<Product />} />
      <Route path="/:slug" element={<Product />} />
      <Route path="/search" element={<SearchedProducts />} />
      <Route path="/rugs" element={<Product />} />
      <Route path="/product/:slug" element={<ProductDetails />} />
      <Route path="/login" element={<Login />} />
      <Route path="/singup" element={<Singup />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/checkoutLogin" element={<CheckoutLogin />} />
      <Route path="checkoutAddress" element={<CheckoutAddress />} />
      <Route path="/checkoutLoginCart" element={<CheckoutLoginCart />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/product-checkout" element={<CheckoutCart />} />
      <Route
        path="/product-checkoutAddress"
        element={<CheckoutAddressCart />}
      />
      <Route path="/product-checkoutSignUp" element={<CheckoutSignUpCart />} />
      <Route path="/product-payment" element={<PaymentCart />} />
      <Route path="/store-locator" element={<StoreLocator />} />
      <Route path="/quality-standards" element={<QualityStandards />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/order-success" element={<Thankyou />} />

      <Route
        path="/my-account"
        element={isLoggedIn ? <Profile /> : <Navigate to="/login" />}
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/media" element={<Media />} />
      <Route path="/collection-list" element={<Collection />} />
      <Route path="/forget-password" element={<Password />} />
      <Route path="/track-order" element={<TrackOrder />} />
      <Route path="/order-status/:email/:orderNo" element={<OrderStatus />} />
      <Route path="/returns-refunds" element={<Refund />} />
      <Route path="/faqs" element={<FAQ />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-inner/:slug" element={<BlogInner />} />
      <Route path="/checkoutSignUp" element={<CheckoutSignup />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route path="/brochure" element={<Brochure />} />
      <Route path="/guest-checkout" element={<GuestCheckout />} />
      <Route path="/guest-checkout-cart" element={<GuestCheckoutCart />} />
      <Route path="/auth/reset-password/:token" element={<NewPassword />} />
      <Route path="/blog-inner-two" element={<BlogInnerTwo />} />
      <Route path="/trade-program" element={<TradeProgram />} />
      <Route path="test" element={<PaymentPage />} />
      <Route path="/transaction" element={<Transaction />} />
      <Route path="/blog-inner-three" element={<BlogInnerThree />} />
    </Routes>
  );
}

export default App;
