import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Login.scss";
import { FaFacebook } from "react-icons/fa";
import { AiFillGoogleCircle } from "react-icons/ai";
import axios from "axios"; // Import axios for API calls if needed
import { baseURL } from "../../utils";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth-actions";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../Common/Loader";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { gapi } from "gapi-script";
import { Helmet } from "react-helmet";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    gapi.load("client:auth2", () => {
      gapi.auth2.init({
        clientId:
          "1001922032220-amo76ik848fbr2gbssnisvfhqhdhl0u7.apps.googleusercontent.com",
      });
    });
  }, []);
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email address is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/auth/login`, values);
      if (response.data.success === true) {
        const { token, user } = response.data;
        const address = {
          address: user.address,
          city: user.city,
          state: user.state,
          country: user.country,
          zip: user.zip,
        };
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("address", JSON.stringify(address));
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", true);
        dispatch(login(token, user));
        setSnackbarMessage("Logged In Successful");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/"), 2000);
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      // console.error("Google Login Error:", error.response?.data?.message);
      setSnackbarMessage(error.response?.data?.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleGoogleLoginSuccess = async (response) => {
    console.log("Google Login Success:", response);

    const userData = {
      type: "google",
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
    };

    try {
      setLoading(true); // Show loader
      const response = await axios.post(`${baseURL}/auth/login`, userData);

      if (response.data.success === true) {
        const { token, user } = response.data;
        const address = {
          address: user.address,
          city: user.city,
          state: user.state,
          country: user.country,
          zip: user.zip,
        };
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("address", JSON.stringify(address));
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", true);
        dispatch(login(token, user));
        setSnackbarMessage("Logged In Successful");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/"), 2000);
      } else {
        setSnackbarMessage(
          response.data.message ||
            "Something Went Wrong! Please try again later"
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something Went Wrong! Please try again later");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Hide loader
    }
  };
  const handleGoogleLoginFailure = (response) => {
    console.log("errorgoogle", response);
    setSnackbarMessage("Something Went Wrong! Please try again later");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  const handleFacebookResponse = (response) => {
    console.log("Facebook Login Success:", response);

    // Send the Facebook response to your backend for further handling
    if (response.accessToken) {
      const userData = {
        accessToken: response.accessToken,
        userID: response.userID,
        email: response.email,
        name: response.name,
      };

      // Send userData to your backend for registration or authentication
      axios
        .post(`${baseURL}/auth/facebook-login`, userData)
        .then((res) => {
          console.log("Login Response:", res.data);
          if (res.data.success) {
            // Handle successful login
            setSnackbarMessage("Facebook login successful!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setTimeout(() => navigate("/dashboard"), 2000); // Redirect after login
          } else {
            setSnackbarMessage(res.data.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        })
        .catch((err) => {
          console.error("Facebook Login Error:", err);
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>Login | D'decor Rugs</title>
        <meta property="og:title" content="Login | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="login-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - Login
          </p>
        </div>
        <div className="login-main-content">
          <div className="login-form-content">
            <h4>LOGIN</h4>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange }) => (
                <Form className="login-form">
                  <div className="form-field-input">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email address *"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                  <div className="form-field-input">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password *"
                    />
                    <span
                      className="password-login-icon"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />

                  <div className="buttons-remember-text">
                    <button type="submit" disabled={loading}>
                      {loading ? <Loader /> : "LOG IN"}
                    </button>
                    {/* <div className="remember-box">
                      <input
                        type="checkbox"
                        name="rememberMe"
                        checked={values.rememberMe}
                        onChange={handleChange}
                      />
                      <p className="remember-text">Remember me</p>
                    </div> */}
                  </div>
                </Form>
              )}
            </Formik>
            <div className="password-create-new">
              <p>
                <Link to="/forget-password">Forgot password ? </Link>
              </p>
              <p>
                <Link to="/singup">Create new account?</Link>
              </p>
            </div>
            <div className="otherSignin">
              <p>
                OR <span class="underline">SIGN IN</span> WITH
              </p>

              <div className="otherLoginButton">
                <FacebookLogin
                  appId="YOUR_FACEBOOK_APP_ID" // Replace with your Facebook App ID
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookResponse}
                  render={(renderProps) => (
                    <button
                      className="facebook-button"
                      onClick={renderProps.onClick}
                    >
                      <FaFacebook /> FACEBOOK
                    </button>
                  )}
                />
                <GoogleLogin
                  clientId="1001922032220-amo76ik848fbr2gbssnisvfhqhdhl0u7.apps.googleusercontent.com" // Replace with your client ID
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  autoLoad={false}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <button
                      className="google-button"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <AiFillGoogleCircle /> GOOGLE
                    </button>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
