import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Refund.scss";
import { Helmet } from "react-helmet";
const Refund = () => {
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  return (
    <>
      <Helmet>
        <title>Returns and Refunds | D'decor Rugs</title>
        <meta
          property="og:title"
          content="Returns and Refunds | D'decor Rugs"
        />
      </Helmet>
      <Header />
      <div class="intro-refund-container">
        <div class="heading-refund">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - <span>Returns & Refunds</span>
          </h2>
        </div>
      </div>

      <div className="refund-page-container">
        <div className="refund-main-content">
          <div className="refund-faqs">
            <h5>RETURNS & REFUNDS</h5>

            <div className="faqs-main-content">
              <ul>
                <div className="refund-faqs-details">
                  <ul>
                    <li>
                      <p>
                        In case you receive a damaged product, you may contact
                        us within 7 days from the date of delivery of the
                        product via email at{" "}
                        <a
                          href="mailto:support@ddecorrugs.com"
                          className="email-link"
                        >
                          support@ddecorrugs.com
                        </a>
                        &nbsp; or call our customer care at Ph: 7718801634 from
                        Monday to Saturday, between 10:00 AM to 6:00 PM (IST)
                        and except Public Holidays. We will investigate the
                        issue and respond to you within 48 working hours. Damage
                        will be assessed, and a solution will be offered. The
                        product may be replaced, or other corrective measures
                        may be taken to address the issue. A reverse pick-up
                        will be scheduled within 3-4 business days. Please
                        ensure that the product you return is unused, unworn,
                        and the original tags are intact.
                      </p>
                    </li>
                  </ul>
                </div>
              </ul>
              <div className="refund-faqs-details">
                <ul>
                  <li>
                    <p>
                      In case product is returned due to any manufacturing
                      defect or the product does not match the item in the Order
                      confirmation mail, the same product will be replaced and
                      sent to you. you may contact us within 7 days from the
                      date of delivery of product, via email at{" "}
                      <a
                        href="mailto:support@ddecorrugs.com"
                        className="email-link"
                      >
                        support@ddecorrugs.com
                      </a>{" "}
                      &nbsp; com or call our customer care at Ph: 7718801634
                      from Monday to Saturday, between 10:00AM to 6:00 PM (IST)
                      and except Public Holidays. Returned products can’t be
                      exchanged with any other new product or different size of
                      the same product. A reverse pick-up will be scheduled
                      within 3-5 business days. Please ensure that the product
                      you return is unused, unworn and the original tags are
                      intact.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="refund-faqs-details">
                <ul>
                  <li>
                    <p>
                      Once the item dispatched from your end reaches our
                      warehouse, the replacement product will be shipped out to
                      you and will reach you in our standard delivery time i.e.
                      3-5 working days for domestic shipments at the same
                      address provided at the time of Original Order.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="refund-faqs-details">
                <ul>
                  <li>
                    <p>
                      In case the product is out of stock, our customer care
                      team will get in touch with you and a refund request will
                      be raised. For paid orders the refund will be processed
                      directly into your debit/credit card or online account.
                      The money will reflect on your card or in your bank
                      account within 7 to 21 working days from when the date
                      refund request was raised.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Refund;
