import React, { Fragment, useState, useEffect } from "react";
import "../../assets/css/OrderSummary.scss";
import axios from "axios";
import { baseURL, formatPrice } from "../../utils";
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
const CheckoutOrderSummary = ({ order, email, type }) => {
  const [code, setCode] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const totalOrderPrice = order.reduce((total, item) => {
    const itemPrice = item.priceSale ?? item.price; // Use priceSale if available
    return total + itemPrice * item.quantity;
  }, 0);

  // Retrieve applied coupons from local storage
  useEffect(() => {
    const appliedCoupons =
      JSON.parse(localStorage.getItem("appliedCoupons")) || {};

    // Check if there is a coupon for any order
    const appliedCoupon = order.some((item) => appliedCoupons[item._id]);
    if (appliedCoupon) {
      setCouponData(appliedCoupons[order[0]._id]); // Assuming the same coupon applies to all orders
    }
  }, [order]);

  const applyDiscount = (total) => {
    if (couponData) {
      const { type, discount } = couponData;
      if (type === "percent") {
        return total - (total * discount) / 100;
      } else if (type === "fixed") {
        return total - discount;
      }
    }
    return total;
  };

  const handleCouponCode = async () => {
    const appliedCoupons =
      JSON.parse(localStorage.getItem("appliedCoupons")) || {};

    // Check if any order has the coupon already applied
    const couponAlreadyApplied = order.some((item) => appliedCoupons[item._id]);

    if (couponAlreadyApplied) {
      const message =
        order.length === 1
          ? "Coupon code already applied for this Rug."
          : "Coupon already applied for one of the Rugs.";
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const body = {
        email: email,
      };
      if (type !== undefined) {
        body.type = type;
      }
      const response = await axios.post(
        `${baseURL}/coupon-codes/${code}`,
        body
      );
      if (response.data.success === true) {
        const coupon = response.data.data;
        setCouponData(coupon);

        // Save coupon to local storage for all orders
        order.forEach((item) => {
          appliedCoupons[item._id] = coupon; // Store coupon with each order's ID
        });
        localStorage.setItem("appliedCoupons", JSON.stringify(appliedCoupons));
        setSnackbarMessage("Coupon applied successfully!");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("Error applying coupon.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleApplyClick = () => {
    handleCouponCode();
  };
  const handleProductRedirect = (e, item) => {
    e.stopPropagation();
    navigate(`/product/${item.slug}?size=${item.sizes.slug}`);
  };
  return (
    <Fragment>
      <div className="order-summary">
        <div className="coupenInput">
          <input
            type="text"
            placeholder="Enter coupon code"
            value={code}
            onChange={handleInputChange}
          />
          <button onClick={handleApplyClick}>APPLY</button>
        </div>
        <div className="order-card">
          <div className="order-summary">
            <h5>Order Summary</h5>
          </div>
          {order.map((orderItem) => (
            <div
              className="order-details-cart"
              key={orderItem.sku}
              onClick={(e) => handleProductRedirect(e, orderItem)}
            >
              <div className="order-image">
                <img src={orderItem.images[0].url} alt={orderItem.name} />
              </div>
              <div className="order-details-payment">
                <div className="order-name-price">
                  <h6>
                    {orderItem.name
                      .toLowerCase()
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </h6>
                  <div className="orderprice">
                    <p>
                      {" "}
                      {formatPrice(
                        (orderItem.priceSale ?? orderItem.price) *
                          orderItem.quantity
                      )}
                    </p>
                  </div>
                </div>
                <div className="order-size-text-and-color">
                  <p>Color: {orderItem.colors[0].name}</p>
                  <p>Size: {orderItem.sizes.name}</p>
                  <p>{orderItem.sku}</p>
                </div>
                <div className="delivery-text">
                  <p>Estimated delivery in 3 to 5 business days</p>
                </div>
              </div>
            </div>
          ))}
          {order.length !== 0 && <div className="divider"></div>}
          <div className="total-order-price">
            <h5>TOTAL</h5>
            <p>₹ {applyDiscount(totalOrderPrice).toFixed(2)}</p>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default CheckoutOrderSummary;
