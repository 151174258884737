import axios from "axios";
// export const baseURL = "https://ddapis.beeonline.in/api/";
export const baseURL = "https://ddecorapi.beeonline.in/api/";
const api = axios.create({
  baseURL: baseURL,
});
const setAuthToken = (token) => {
  if (token) {
    // If the token exists, set it as the default Authorization header for all requests
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // If there's no token, remove the Authorization header
    delete api.defaults.headers.common["Authorization"];
  }
};
export const fetchBanners = async () => {
  try {
    const response = await api.get("/banner");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchHomeCategories = async () => {
  try {
    const response = await api.get("/is-home-categories");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchNewArrivals = async () => {
  try {
    const response = await api.get("/is-arrival-product");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchFeaturedCollection = async () => {
  try {
    const response = await api.get("/feature-collection-home");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchBestSellers = async () => {
  try {
    const response = await api.get("/best-seller-product");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchProducts = async (slug, filters = {}) => {
  try {
    const queryParams = new URLSearchParams(filters).toString(); // Convert filters object to query string
    const response = await api.get(`/products-filter/${slug}?${queryParams}`); // Attach the query parameters to the API URL
    const result = response.data;
    return {
      count: result.total,
      products: result.data,
      range: result.Range,
    };
  } catch (error) {
    console.log(error);
  }
};
export const fetchSearchedProducts = async (slug, filters = {}) => {
  try {
    // Remove 'q' from filters if it exists to avoid duplication
    const { q, ...remainingFilters } = filters;

    // Create query parameters from the remaining filters
    const queryParams = new URLSearchParams(remainingFilters).toString();

    // Build the URL by including 'q' only once
    const url = `/search?q=${slug}${queryParams ? `&${queryParams}` : ""}`;
    const response = await api.get(url);
    const result = response.data;

    return {
      count: result.count,
      products: result.data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchFeaturedIn = async () => {
  try {
    const response = await api.get("/logo");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchProductDetails = async (slug, size) => {
  try {
    const response = await api.get(`products/${slug}`, {
      params: { size },
    });
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateUser = async (userData, token) => {
  try {
    // Set the token in the header
    setAuthToken(token);

    // Make the PUT request to update the user profile
    const response = await api.put("/users/profile", userData); // Send user data in the body
    const result = response.data;

    // Return the response data
    return result;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.log(error);
  }
};
export const fetchReviewById = async (id) => {
  try {
    const response = await api.get(`review-pid/${id}`);
    const result = response.data;
    const reviews = result.reviews;
    const count = result.count;
    return { reviews, count };
  } catch (error) {
    console.log(error);
  }
};

export const getReviewByUser = async (id, userId) => {
  try {
    const response = await api.get(`reviews-user/${id}/${userId}`);
    const result = response.data;
    const reviews = result.review;
    // const count = reviews.count;
    return reviews;
  } catch (error) {
    console.log(error);
  }
};

export const addReview = async (review, token) => {
  try {
    setAuthToken(token);
    const response = await api.post("/reviews", review);
    const result = response.data;
    const ratingData = result.data;
    const success = result.success;
    return { ratingData, success };
  } catch (error) {
    console.log(error);
  }
};
export const getSimilarProducts = async (id) => {
  try {
    const response = await api.get(`similar-products/${id}`);
    const result = response.data;
    // const reviews = result.review;
    // const count = reviews.count;
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : [];
};
export const fetchOrders = async (token) => {
  try {
    // Set the token in the header before making the request
    setAuthToken(token);

    const response = await api.get(`/users/invoice-order`);
    const result = response.data;

    return result;
  } catch (error) {
    console.log(error);
  }
};
export const formatPrice = (price) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(price);
};
export const fetchBrochure = async () => {
  try {
    const response = await api.get("/brochure");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchBlogs = async () => {
  try {
    const response = await api.get("/blogs");
    const result = response.data;
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
