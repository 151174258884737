import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/GuestCheckout.scss";
import CheckoutOrderSummary from "../Common/CheckoutOrderSummary";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { orderActions } from "../../store/order";
import * as Yup from "yup";
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ValueScope } from "ajv/dist/compile/codegen";
import axios from "axios";
import { baseURL } from "../../utils";
import { cartActions } from "../../store/cart";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Loader from "../Common/Loader";
import RenderRazorpay from "../Common/RenderRazorPay";
import { Helmet } from "react-helmet";
const indianStates = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

const GuestCheckout = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderItems = useSelector((state) => state.order.order);
  const cartItems = useSelector((state) => state.cart.cart);
  const [isStateOpen, setIStateOpen] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  const [email, setEmail] = useState("");
  const [dataComingFrom, setDataComingFrom] = useState(
    localStorage.getItem("dataComingFrom")
  );
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
    keyId: null,
    keySecret: null,
  });
  const stateRef = useRef(null);
  const countryRef = useRef(null);
  useEffect(() => {
    setDataComingFrom(localStorage.getItem("dataComingFrom"));
    window.scrollTo(0, 0);
    const handleClickOutside = (event) => {
      if (
        stateRef.current &&
        !stateRef.current.contains(event.target) &&
        countryRef.current &&
        !countryRef.current.contains(event.target)
      ) {
        setIStateOpen(false);
        setIsCountryOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOptionSelect = (setFieldValue, fieldName, value) => {
    setFieldValue(fieldName, value);
    if (fieldName === "state") setIStateOpen(false);
    if (fieldName === "country") setIsCountryOpen(false);
  };
  useEffect(() => {
    const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
    if (savedOrder.length > 0) {
      dispatch(orderActions.setOrder(savedOrder));
    }
  }, [dispatch]);
  // Save order to localStorage when orderItems are updated

  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(orderItems));
  }, [orderItems]);
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);
  const bankData = [
    { id: 1, slug: "upi", name: "UPI" },
    { id: 2, slug: "bank-transfer", name: "Bank transfer" },
    { id: 3, slug: "pay-online", name: "Pay Online" },
    { id: 4, slug: "COD", name: "Cash On Delivery" },
  ];
  const handlePaymentMethod = (e, slug) => {
    e.stopPropagation();
    setPaymentMethod(slug);
  };
  // const handleRedirect = (item) => {
  //   window.location.href = item;
  // };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone Number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  });
  const handleSubmit = async (values) => {
    try {
      const dataToSend = {
        type: "guest-checkout",
        firstName: values.firstName,
        lastName: values.lastName,
        address: values.address,
        email: values.email,
        phone: values.phone,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
      };
      const response = await axios.post(`${baseURL}/auth/register`, dataToSend);

      if (response.data.success === true) {
        handlePayment(values);
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePayment = async (data) => {
    const dataComingFrom = localStorage.getItem("dataComingFrom");
    const itemsToUse = dataComingFrom === "buy-now" ? orderItems : cartItems;
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      email: data.email,
      phone: data.phone,
      city: data.city,
      state: data.state,
      country: data.country,
      zip: data.zip,
    };

    // Load applied coupons from localStorage
    const appliedCoupons =
      JSON.parse(localStorage.getItem("appliedCoupons")) || {};

    // Find the first coupon code associated with any item in the order
    let overallCouponCode = "";
    for (const item of itemsToUse) {
      if (appliedCoupons[item._id]) {
        overallCouponCode = appliedCoupons[item._id].code;
        break;
      }
    }

    const order = itemsToUse.map((item) => ({
      _id: item._id,
      name: item.name,
      slug: item.slug,
      price: item.price,
      priceSale: item.priceSale,
      available: item.available,
      pid: item._id,
      quantity: item.quantity,
      size: item.sizes?.slug,
      image: item.images[0]?.url,
      colors: item.colors[0]?.name,
      shapes: item.shapes[0]?.name,
      patterns: item.patterns[0]?.name,
      rooms: item.rooms[0]?.name,
      composition: item.composition,
      technique: item.technique,
      subtotal: item.price,
      sku: item.sku,
    }));

    const values = {
      couponCode: overallCouponCode, // Apply overall coupon code here
      paymentMethod: "Razorpay",
      items: order,
      user: userData,
      totalItems: itemsToUse.length,
      shipping: 0,
      paymentId: "",
    };

    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/orders`, values);

      setOrderDetails({
        orderId: response.data.razorpayOrder.id,
        currency: response.data.razorpayOrder.currency,
        amount: response.data.total,
        keyId: response.data.keyId,
        keySecret: response.data.keySecret,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          transaction_id: response.data.razorpayOrder.id,
          currency: response.data.razorpayOrder.currency,
          value: response.data.total,
          items: itemsToUse.map((item) => ({
            item_id: item._id,
            item_name: item.name,
            price: item.priceSale ?? item.price,
            quantity: item.quantity,
            item_variant: item.sizes.length !== 0 ? item.sizes.name : "",
          })),
          couponCode: overallCouponCode,
        },
      });
      setDisplayRazorpay(true);
    } catch (error) {
      console.error("Payment failed:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>Guest Checkout| D'decor Rugs</title>
        <meta property="og:title" content="Guest Checkout | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="guest-checkout-page-container">
        <div className="guest-checkout-main-content">
          <div className="guest-checkout-left-side-box">
            <h3>SHIPPING INFORMATION</h3>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                country: "India",
                zip: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, errors, touched, handleChange }) => (
                <>
                  <Form className="address-form-inputs">
                    <div className="label-and-input-box-guest">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email *"
                        onChange={(e) => {
                          handleChange(e); // Update Formik values
                          setEmail(e.target.value); // Update the state
                        }}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="two-input-box">
                      <div className="label-and-input-box-guest">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name *"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="label-and-input-box-guest">
                        <Field
                          type="text"
                          name="lastName"
                          placeholder="Last Name *"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="two-input-box">
                      <div className="label-and-input-box-guest">
                        <Field
                          type="text"
                          name="address"
                          placeholder="Street Address *"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="label-and-input-box-guest">
                        <Field type="text" name="city" placeholder="City*" />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="two-input-box">
                      <div className="label-and-input-box-guest">
                        <Field
                          type="text"
                          name="zip"
                          className="three-input"
                          placeholder="Pincode *"
                        />
                        <ErrorMessage
                          name="zip"
                          component="div"
                          className="error"
                        />
                      </div>
                      {/* <div
                        className="form-field-input state-field"
                        ref={stateRef}
                      >
                        <div
                          className="dropdown"
                          onClick={() => setIStateOpen(!isStateOpen)}
                        >
                          <Field
                            type="text"
                            name="state"
                            placeholder="State"
                            className="input-with-icons"
                            readOnly
                          />
                          {isStateOpen ? (
                            <MdKeyboardArrowUp size={24} />
                          ) : (
                            <MdKeyboardArrowDown size={24} />
                          )}
                        </div>
                        {isStateOpen && (
                          <div className="dropdown-options">
                            {indianStates.map((option, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleOptionSelect(
                                    setFieldValue,
                                    "state",
                                    option
                                  )
                                }
                                className={`dropdown-option ${
                                  values.state === option ? "selected" : ""
                                }`} // Add 'selected' class for highlighting
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div> */}
                      <div className="form-field-state-field">
                        <div
                          className="form-field-input-state state-field"
                          ref={stateRef}
                        >
                          <div className="dropdown">
                            <Field
                              type="text"
                              name="state"
                              placeholder="State"
                              className="input-with-icons"
                              value={values.state}
                              onClick={() => setIStateOpen(true)} // Open dropdown on click
                              onChange={(e) => {
                                setFieldValue("state", e.target.value); // Update Formik state
                                setIStateOpen(true); // Ensure dropdown stays open
                              }}
                            />
                            {isStateOpen ? (
                              <MdKeyboardArrowUp
                                size={24}
                                onClick={() => setIStateOpen(false)} // Close dropdown
                              />
                            ) : (
                              <MdKeyboardArrowDown
                                size={24}
                                onClick={() => setIStateOpen(true)} // Open dropdown
                              />
                            )}
                          </div>

                          {isStateOpen && (
                            <div
                              className="dropdown-options"
                              style={{ maxHeight: "150px", overflowY: "auto" }}
                            >
                              {[
                                // Matches first
                                ...indianStates.filter((option) =>
                                  option
                                    .toLowerCase()
                                    .startsWith(values.state.toLowerCase())
                                ),
                                // Non-matches next
                                ...indianStates.filter(
                                  (option) =>
                                    !option
                                      .toLowerCase()
                                      .startsWith(values.state.toLowerCase())
                                ),
                              ].map((option, index) => (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleOptionSelect(
                                      setFieldValue,
                                      "state",
                                      option
                                    )
                                  }
                                  className={`dropdown-option ${
                                    values.state.toLowerCase() ===
                                    option.toLowerCase()
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                      {/* Country Dropdown */}
                      <div
                        className="form-field-input country-field state-field"
                        ref={countryRef}
                      >
                        <div
                          className="dropdown"
                          onClick={() => setIsCountryOpen(!isCountryOpen)}
                        >
                          <Field
                            type="text"
                            name="country"
                            placeholder="Country"
                            className="input-with-icons"
                            readOnly
                          />
                          {isCountryOpen ? (
                            <MdKeyboardArrowUp size={24} />
                          ) : (
                            <MdKeyboardArrowDown size={24} />
                          )}
                        </div>
                        {isCountryOpen && (
                          <div className="dropdown-options">
                            <div
                              onClick={() =>
                                handleOptionSelect(
                                  setFieldValue,
                                  "country",
                                  "India"
                                )
                              }
                              className={`dropdown-option ${
                                values.country === "India" ? "selected" : ""
                              }`}
                            >
                              India
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="label-and-input-box-guest">
                      {isFocused || values.phone ? (
                        <span className="default-india-code-guest">+91</span>
                      ) : null}
                      <Field
                        type="text"
                        name="phone"
                        className="two-fix-width-input"
                        placeholder="Phone Number *"
                        style={{
                          paddingLeft:
                            isFocused || values.phone ? "51px" : "20px", // Adjust 12px to your normal padding
                        }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="payment-option">
                      {/* <h5>PAYMENT OPTIONS</h5> */}
                      {/* {bankData.map((item) => (
                        <div className="all-options-of-payment" key={item.id}>
                          <div className="bank-details">
                            <div className="circle-name">
                              <div
                                className={`emty-circle ${
                                  paymentMethod === item.slug
                                    ? "filled-circle"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handlePaymentMethod(e, item.slug)
                                }
                              ></div>
                              <h6>{item.name}</h6>
                            </div>
                            <p>
                              {" "}
                              ₹{" "}
                              {orderItems.reduce(
                                (total, item) =>
                                  total + item.price * item.quantity,
                                0
                              )}
                            </p>
                          </div>
                        </div>
                      ))} */}
                    </div>
                    <div className="order-button">
                      <button disabled={loading === true}>
                        {loading ? <Loader /> : "PAY NOW"}{" "}
                        {/* Show loader or button text */}
                      </button>
                    </div>
                  </Form>
                </>
              )}
              {/* </Formik> */}
            </Formik>
          </div>
          <CheckoutOrderSummary
            order={dataComingFrom === "buy-now" ? orderItems : cartItems}
            email={email}
            type={"guest"}
          />
        </div>
      </div>
      <Footer />
      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={orderDetails.keyId}
          keySecret={orderDetails.keySecret}
          dataComingFrom={dataComingFrom}
          setDisplayRazorpay={setDisplayRazorpay}
          items={dataComingFrom === "buy-now" ? orderItems : cartItems}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GuestCheckout;
