import React, { Fragment, useEffect } from "react";
import "../../assets/css/Terms.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Helmet } from "react-helmet";
const TermsAndCondition = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleRedirect = (item) => {
    window.location.href = item;
  };

  return (
    <Fragment>
      <Helmet>
        <title>Terms and Conditions | D'decor Rugs</title>
        <meta
          property="og:title"
          content="Terms and Conditions | D'decor Rugs"
        />
      </Helmet>
      <Header />
      <div className="intro-terms-container">
        <div className="heading-terms">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - <span>Terms & Conditions</span>
          </h2>
        </div>
      </div>

      <div className="terms-content">
        <h2>Terms & Conditions</h2>
        <div className="terms-content-details">
          <ol>
            {/* Section 1: General */}
            <li>
              General
              <ul>
                <li>
                  You hereby consent and agree that you have read and fully
                  understood the Privacy Policy of the Website.
                </li>
                <li>
                  These Terms & Conditions apply to products purchased via
                  &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    https://www.ddecorrugs.com
                  </a>
                  &nbsp; or placed through social media, or offline on phone or
                  chat or through our customer care team which are all assets
                  managed by D’Decor, a Partnership Firm.
                </li>
                <li>
                  If you do not agree with the supply terms, please do not
                  access the website or any other asset of D’Decor for any
                  purchase or service.
                </li>
              </ul>
            </li>
            {/* Section 2: Business and Product */}
            <li>
              Business and Product
              <ul>
                <li>
                  The websites are a platform that facilitates the online sale
                  and purchase of merchandise and services ("Services") offered
                  by third-party vendors and service providers ("Vendor/s").
                  Title to the merchandise ("Products") will pass directly from
                  the Vendors to you.
                </li>
                <li>
                  &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    https://www.ddecorrugs.com
                  </a>
                  &nbsp;has the right to change or discontinue any service at
                  any time, without notice. &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    https://www.ddecorrugs.com
                  </a>
                  &nbsp; may add or modify the contents, procedures, modes,
                  processes or conditions of purchase at any time. &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    https://www.ddecorrugs.com
                  </a>
                  &nbsp; shall not be liable to you or to any third party for
                  any modification, suspension or discontinuance of any aspect
                  of the services.
                </li>
                <li>
                  &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    https://www.ddecorrugs.com
                  </a>
                  &nbsp; enables the sale of a variety of products. Shipping for
                  all the products on the website shall be per the company's
                  policy, which may be changed by &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    https://www.ddecorrugs.com
                  </a>
                  &nbsp; from time to time.
                </li>
                <li>
                  Products are sold for the end use of the customer and not for
                  further re-sale in any manner.
                </li>
                <li>
                  The product price displayed at the time of placing the order
                  is the final price, and any price revision thereafter is not
                  applicable to that order.
                </li>
                <li>
                  Our product prices are inclusive of taxes if sold within India
                  and exclusive of international taxes if sold outside India.
                </li>
              </ul>
            </li>
            {/* Section 3: Shipping*/}
            <li>
              Shipping
              <ul>
                <li>
                  Within India, we ship through registered courier company ‘Blue
                  Dart”. Our delivery partners will attempt to deliver the
                  package thrice before they return it to us. The customer will
                  have to pay extra after the 3rd delivery attempt. Please
                  provide a mobile number that you are available at, and your
                  complete shipping address, along with landmark and with the
                  pin-code. This will help us to ensure smooth delivery of your
                  order. At the time of delivery, if the packaging looks damaged
                  or tampered, please do not accept the package and inform to us
                  at{" "}
                  <a
                    href="mailto:support@ddecorrugs.com"
                    className="email-link"
                  >
                    support@ddecorrugs.com
                  </a>{" "}
                  &nbsp;.
                </li>
                <li>
                  Shipping and handling rates vary based on the product, its
                  size and volume, the packaging used and the shipping
                  destination. You will see the final shipping and handling
                  charges at the time of checkout after you provide the shipping
                  address for your order.
                </li>
                <li>
                  Domestic orders are usually delivered within 3-5 business days
                  between Monday to Saturday and except Public Holidays.
                </li>
                <li>
                  <p style={{ color: "red" }}>
                    We do not offer International orders currently, but we
                    should be able to accept orders in a couple of days.
                    International Orders will usually be delivered within 15-20
                    business days between Monday to Saturday and except Public
                    Holidays.
                  </p>
                </li>
                <li>
                  Our delivery partners will return the product to us if
                  unreceived at your address. Please provide the complete &
                  accurate shipping address including postal/zip code and a
                  mobile number. This will help us in delivering your order
                  faster. If a non-delivery occurs on account of a mistake by
                  you (i.e. wrong name, address or contact number) any extra
                  cost towards re-delivery shall be claimed by the user placing
                  the order.
                </li>
              </ul>
            </li>
            {/* Section 4: Tracking your Orders */}
            <li>
              Tracking your Orders
              <ul>
                <li>
                  Orders that have been processed and shipped can be tracked
                  using the consignment/tracking number on the shipping agency’s
                  website. Once your order is shipped, we will mail you your
                  tracking number along with information about the shipping
                  agency
                </li>
              </ul>
            </li>
            {/* Section 5: Mode of Payments */}
            <li>
              Mode of Payments
              <ul>
                <li>
                  For shipping to India, we accept all major debit & credit
                  cards (including Mastercard, Visa & American Express) and Net
                  Banking options across major banks.
                </li>
                <li>
                  If your card has been debited multiple times at the payment
                  gateway(s) while making a single purchase, call our customer
                  care at Ph: +91 22 61152560 or mail us at{" "}
                  <a
                    href="mailto:support@ddecorrugs.com"
                    className="email-link"
                  >
                    support@ddecorrugs.com
                  </a>
                  , and we will resolve it for you. Our customer care team is
                  available from Monday to Saturday, from 9:00 am till 6:00 pm
                  and except Public Holidays.
                </li>
                <li>
                  We reserve the right to recover the cost of goods, collection
                  charges, and lawyers’ fees from persons using the Site
                  fraudulently. We reserve the right to initiate legal
                  proceedings against such persons for fraudulent use of the
                  Site and any other unlawful acts or omissions in breach of
                  these terms and conditions.
                </li>
              </ul>
            </li>
            {/* Section 6: Limited User */}
            <li>
              Limited User
              <ul>
                <li>
                  The User agrees and undertakes not to reverse engineer,
                  modify, copy, distribute, transmit, display, perform,
                  reproduce, publish, license, create derivative works from,
                  transfer, or sell any information or software obtained from
                  the Website. Limited reproduction and copying of the content
                  of the Website is permitted provided that D’Decor &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    (https://www.ddecorrugs.com)
                  </a>
                  &nbsp; name is stated as the source and prior written
                  permission of D’Decor &nbsp;
                  <a href="#https://www.ddecorrugs.com" className="email-link">
                    (https://www.ddecorrugs.com)
                  </a>
                  &nbsp; is obtained. It is also clarified that unlimited or
                  wholesale reproduction, copying of the content for commercial
                  or noncommercial purposes and unwarranted modification of data
                  and information within the content of the Website is not
                  permitted.
                </li>
              </ul>
            </li>
            {/* Section 7: Intellectual Property */}
            <li>
              Intellectual Property
              <ul>
                <li>
                  D’Decor and its suppliers and licensors expressly reserve all
                  intellectual property rights in all text, programs, products,
                  processes, technology, content and other materials, which
                  appear on this Site. Access to this Site does not confer and
                  shall not be considered as conferring upon anyone any license
                  under any of Our or any third party's intellectual property
                  rights. All rights, including copyright, in this website are
                  owned by or licensed to D’Decor. Any use of this website or
                  its contents, including copying or storing it or them in whole
                  or part, other than for your own personal, non-commercial use
                  is prohibited without the permission of D’Decor. You may not
                  modify, distribute or re-post anything on this website for any
                  purpose. 
                </li>
                <li>
                  The D’Decor names and logos and all related product and
                  service names, design marks and slogans are the trademarks or
                  service marks of D’Decor. All other marks are the property of
                  their respective companies. No trademark or service mark
                  license is granted in connection with the materials contained
                  on this Site. Access to this Site does not authorize anyone to
                  use any name, logo or mark in any manner. References on this
                  Site to any names, marks, products or services of third
                  parties or hypertext links to third party sites or information
                  are provided solely as a convenience to you and do not in any
                  way constitute or imply Our endorsement, sponsorship or
                  recommendation of the third party, information, product or
                  service. {" "}
                </li>
                <li>
                  We are not responsible for the content of any third-party
                  sites and do not make any representations regarding the
                  content or accuracy of material on such sites. If you decide
                  to link to any such third-party websites, you do so entirely
                  at your own risk. 
                </li>
                <li>
                  All materials, including images, text, illustrations, designs,
                  icons, photographs, programs, music clips or downloads, video
                  clips and written and other materials that are part of this
                  Site (collectively, the "Contents") are intended solely for
                  personal, non-commercial use. You may download or copy the
                  Contents and other downloadable materials displayed on the
                  Site for your personal use only. No right, title or interest
                  in any downloaded materials or software is transferred to you
                  because of any such downloading or copying. You may not
                  reproduce (except as noted above), publish, transmit,
                  distribute, display, modify, create derivative works from,
                  sell or participate in any sale of or exploit in any way, in
                  whole or in part, any of the Contents, the Site or any related
                  software. All software used on this Site is the property of
                  D’Decor or its suppliers and protected by Indian and
                  international copyright laws. The Contents and software on
                  this Site may be used only as a shopping resource. Any other
                  use, including the reproduction, modification, distribution,
                  transmission, republication, display, or performance, of the
                  Contents on this Site is strictly prohibited.
                </li>
              </ul>
            </li>
            {/* Section 8: User warranty and Representation*/}
            <li>
              User warranty and Representation
              <ul>
                <li>
                  The User guarantees, warrants, and certifies that You are the
                  owner of the content which you submit or otherwise authorized
                  to use the content and that the content does not infringe upon
                  the property rights, intellectual property rights or other
                  rights of others.
                </li>
              </ul>
            </li>
            {/* Section 9:Indemnity*/}
            <li>
              Indemnity
              <ul>
                <li>
                  You agree to defend, indemnify and hold harmless D’Decor, its
                  partners, employees, officers, agents and their successors and
                  assigns from and against any and all claims, liabilities,
                  damages, losses, costs and expenses, including attorney's
                  fees, caused by or arising out of claims based upon your
                  actions or inactions, which may result in any loss or
                  liability to Us or any third party including but not limited
                  to breach of any warranties, representations or undertakings
                  or in relation to the non-fulfillment of any of your
                  obligations under these Terms or arising out of the your
                  violation of any applicable laws, regulations including but
                  not limited to Intellectual Property Rights, payment of
                  statutory dues and taxes, claim of libel, defamation,
                  violation of rights of privacy or publicity, loss of service
                  by other subscribers and infringement of intellectual property
                  or other rights. This clause shall survive the expiry or
                  termination of these Terms.
                </li>
              </ul>
            </li>
            {/* Section 10:Indemnity*/}
            <li>
              Termination
              <ul>
                <li>
                  We may suspend or terminate your use of the Website or any
                  service if we believe, in our sole and absolute discretion
                  that You have breached any of the Terms. We may also in our
                  sole discretion and at any time discontinue providing the
                  service, or any part thereof. Termination of your access to
                  the use of the Website may be affected without prior notice to
                  you, and you acknowledge and agree that We may immediately bar
                  any further access to the Website. 
                </li>
                <li>
                  If You or We terminates your use of the Website or any
                  service, we may delete any content or other materials relating
                  to your use of the Website and will have no liability to you
                  or any third party for doing so. You shall be liable to pay
                  for any service or product that you have already ordered till
                  the time of Termination by either party whatsoever.
                </li>
              </ul>
            </li>
            {/* Section 11:Disclaimer of Warranties*/}
            <li>
              Disclaimer of Warranties
              <h6>You expressly understand and agree that</h6>
              <ul>
                <li>
                  You’re accessing or use of the Website is at your sole risk.
                  The Website is provided on an "as is" and "as available"
                  basis. We and our licensors, suppliers, vendors, parent,
                  holding, subsidiary and related companies, affiliates,
                  officers, agents and employees expressly disclaim all
                  warranties of any kind, whether express or implied, including,
                  but not limited to the implied warranties of merchantability,
                  fitness for a particular purpose and non-infringement. 
                </li>
                <li>
                  We, our associates and technology partners make no
                  representations or warranties about the accuracy, reliability,
                  completeness, current-ness and/or timeliness of any content,
                  information, software, text, graphics, links or communications
                  provided on or using the Site or that the operation of the
                  Site will be error free and/or uninterrupted. Consequently, we
                  assume no liability whatsoever for any monetary or other
                  damage suffered by you on account of the delay, failure,
                  interruption, or corruption of any data or other information
                  transmitted in connection with use of the Site; and/or any
                  interruption or errors in the operation of the Site. 
                </li>
                <li>
                  We and our licensors, suppliers, vendors, parent, holding,
                  subsidiary and related companies, affiliates, officers, agents
                  and employees make no warranty that
                  <div className="nested-list">
                    <ul>
                      <li>
                        The service will meet your requirements, the service
                        will be uninterrupted, timely, secure, or error-free.
                      </li>
                      <li>
                        The service will always be accessible at any time or via
                        the channel selected or used by you.
                      </li>
                      <li>
                        {" "}
                        The information, content or advertisements
                        (collectively, the "materials") contained on,
                        distributed through, or linked, downloaded or accessed
                        from or through the service, or the results that may be
                        obtained from the use of the service, will be accurate
                        or reliable.{" "}
                      </li>
                      <li>
                        This disclaimer does not apply to any product warranty
                        offered by the manufacturer of the product as specified
                        in the product specifications.
                      </li>
                      <p style={{ marginTop: "30px" }}>
                        This disclaimer constitutes an essential part of the
                        Terms.
                      </p>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
            {/* Section 12:Indemnity*/}
            <li>
              Limitation of Liability
              <ul>
                <li>
                  To the fullest extent permitted under applicable law, We, our
                  associates, parent companies, or suppliers shall not be liable
                  for any indirect, incidental, special, incidental,
                  consequential or exemplary damages, including but not limited
                  to, damages for loss of profits, goodwill, use, data or other
                  intangible losses arising out of or in connection with the
                  Site, its services or these Terms. 
                </li>
                <li>
                  Without prejudice to the generality of the section above, our
                  total liability to you for all liabilities arising out of
                  these Terms, be it in tort or contract, is limited to the
                  value of the product ordered by you.
                </li>
              </ul>
            </li>
            {/* Section 13:Website Security*/}
            <li>
              Website Security <br /> You are prohibited from violating or
              attempting to violate the security of the Site, including, without
              limitation.
              <ul>
                <li>
                  Accessing data not intended for you or logging onto a server
                  or an account which you are not authorized to access
                </li>
                <li>
                  Attempting to probe, scan or test the vulnerability of a
                  system or network or to breach security or authentication
                  measures without proper authorization
                </li>
                <li>
                  Attempting to interfere with service to any other user, host
                  or network, including, without limitation, via means of
                  submitting a virus to the Site, overloading, "flooding,"
                  "spamming," "mail bombing," or "crashing"
                </li>
                <li>
                  Sending unsolicited email, including promotions and/or
                  advertising of products or services
                </li>
                <li>
                  Forging any TCP/IP packet header or any part of the header
                  information in any email or newsgroup posting Violations of
                  system or network security may result in civil or criminal
                  liability. We will investigate occurrences that may involve
                  such violations and may involve, and cooperate with, law
                  enforcement authorities in prosecuting users who are involved
                  in such violations. You agree not to use any device, software,
                  or routine to interfere or attempt to interfere with the
                  proper working of this Site or any activity being conducted on
                  this Site. You agree, further, not to use or attempt to use
                  any engine, software, tool, agent, or other device or
                  mechanism (including without limitation browsers, spiders,
                  robots, avatars, or intelligent agents) to navigate or search
                  this Site other than the search engine and search agents
                  available from Us on this Site and other than generally
                  available third-party web browsers (e.g., Internet Explorer,
                  Google Chrome, etc.).
                </li>
              </ul>
            </li>
            {/* Section 14:Entire Agreement*/}
            <li>
              Entire Agreement
              <ul>
                <li>
                  These Terms and Conditions of Use constitute the entire
                  agreement between you and D’Decor and govern your use of the
                  Website, superseding any prior understandings and agreements
                  between you and us and any previous statements or
                  representations from either party to the other party. These
                  Terms do not apply to any affiliate services, third-party
                  content, or third-party software that does not or cannot
                  reasonably be deemed to form part of the Website which may be
                  provided to you by our licensors, suppliers, vendors, parent,
                  holding, subsidiary, or related companies, other affiliates,
                  or other third parties, which may be subject to additional
                  terms and conditions imposed by that party. You also may be
                  subject to additional terms and conditions that may apply when
                  you use affiliate services, third-party content, or
                  third-party software.
                </li>
              </ul>
            </li>
            {/* Section 15:Non-Transferability*/}
            <li>
              Non-Transferability
              <ul>
                <li>
                  You agree that your account on the Website is non-transferable
                  and any rights to your Account ID or contents within your
                  account terminate upon your death. Upon receipt of a copy of a
                  death certificate, your account may be terminated and all
                  contents therein permanently deleted.
                </li>
              </ul>
            </li>
            {/* Section 16:governing Law and Jurisdiction*/}
            <li>
              governing Law and Jurisdiction
              <ul>
                <li>
                  These Terms and the relationship between you and us shall be
                  governed by the laws of the Republic of India without regard
                  to its conflict of law provisions. The Courts at Mumbai, India
                  shall have exclusive jurisdiction in any proceedings arising
                  out of these Terms.
                </li>
              </ul>
            </li>
            {/* Section 17:Contact Us*/}
            <li>
              Contact Us
              <ul>
                <li>
                  Please send any questions or comments (including all inquiries
                  unrelated to copyright infringement) regarding this website to{" "}
                  <a
                    href="mailto:support@ddecorrugs.com"
                    className="email-link"
                  >
                    support@ddecorrugs.com
                  </a>{" "}
                  &nbsp; In accordance with Information Technology Act 2000 and
                  rules made there under, the name and contact details of the
                  Grievance Officer are provided below
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className="term-condition-contact-details">
          <h5>Grievance Officer</h5>
          <h5>Mr. Akbar Shaikh</h5>
          <p>
            S-14, 5 th Floor, Unit No. 1452 Solitaire Corporate Park Andheri,
            Ghatkopar Link Rd, Chakala, Andheri (E) Mumbai-400093
          </p>
          <p>Telephone: +91 7718801634</p>
          <p>
            Email:{" "}
            <a href="mailto:support@ddecorrugs.com" className="email-link">
              support@ddecorrugs.com
            </a>{" "}
          </p>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TermsAndCondition;
