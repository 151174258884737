import React, { useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/NewPassword.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Helmet } from "react-helmet";
const NewPassword = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must contain one lowercase, one uppercase, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const handleSubmit = async (values) => {
    // Convert dateOfBirth from DD-MM-YYYY to YYYY-MM-DD format for API submission

    const dataToSend = {
      newPassword: values.password,
      token,
    };

    try {
      const response = await axios.post(
        `${baseURL}/auth/reset-password`,
        dataToSend
      );

      if (response.data.success === true) {
        setSnackbarMessage("Password resetted successfully  .");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/login"), 2000); // Redirect after 2 seconds
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <Helmet>
        <title>Reset Password| D'decor Rugs</title>
        <meta property="og:title" content="Reset Password | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="new-password-page-container">
        <div className="header-of-login">
          <p>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - My account
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - My account
          </p>
        </div>
        <div className="new-password-main-content">
          <div className="details-of-new-password">
            {/* <h6>Enter New Password </h6> */}
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="new-password-form">
                <div className="form-field-newPass">
                  <label>Enter New Password</label>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                  />
                  <span
                    className="password-main-forget-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                  <p className="password-text">
                    Must contain minimum one lowercase (a-z), Uppercase (A-Z),
                    Special Characters (!@#$%&*) and number (0 - 9)
                  </p>
                </div>
                <div className="form-field-newPass">
                  <label>Confirm Password</label>
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                  />
                  <span
                    className="confirmpassword-main-forget-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                <button className="reset-password-button">
                  RESET PASSWORD
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewPassword;
