import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { baseURL } from "../../utils";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart";
import { orderActions } from "../../store/order";
// Function to load script and append in DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({
  orderId,
  keyId,
  keySecret,
  currency,
  amount,
  dataComingFrom,
  setDisplayRazorpay,
  items,
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.order);
  const cartItems = useSelector((state) => state.cart.cart);
  // To load razorpay checkout modal script.

  useEffect(() => {
    const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
    if (savedOrder.length > 0) {
      dispatch(orderActions.setOrder(savedOrder));
    }
  }, [dispatch]);
  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(orderItems));
  }, [orderItems]);
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  // informing server about payment
  const handlePayment = async (
    status,
    razorpay_order_id,
    razorpay_payment_id,
    razorpay_signature
  ) => {
    try {
      const response = await Axios.post(`${baseURL}/verify-payment`, {
        status,
        razorpay_order_id,
        razorpay_payment_id,
        razorpay_signature,
      });
      if (response.data.success === true) {
        setDisplayRazorpay(false);
        const items = response.data.order.items;
        const user = response.data.order.user;
        const fbPayload = {
          event_name: "Purchase",
          event_time: Math.floor(new Date().getTime() / 1000),
          event_source_url: window.location.href,
          user_data: {
            email: user.email
              ? CryptoJS.SHA256(user.email.trim().toLowerCase()).toString()
              : null,
            phone: user.phone
              ? CryptoJS.SHA256(user.phone.trim()).toString()
              : null,
          },
          custom_data: {
            currency: "INR",
            value: amount,
            content_ids: items.map((item) => item._id),
            contents: items.map((item) => ({
              id: item._id,
              quantity: item.quantity,
              item_price: item.priceSale ?? item.price,
            })),
            content_type: "product",
          },
          event_id: razorpay_payment_id,
        };
        console.log(fbPayload);
        fetch("https://graph.facebook.com/v15.0/1240013473730710/events", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer EAAHrafXcznMBOZCqbbRbUZBfLi2jz3hcPUMBUvolo4BAkIG5LCLVS28VeVSWxzLj92Qrc3PbtoTKUA2sdz2bgWZBQ38aNsp3ZBURTBYQkgljaacAVfdrIsRydfxhCrcuZCy7hrGPgkomW04mEBDx9DMMWq3GDFDNqWLShrKu6BuxCTOIVQQZAilYNqM44B4SBpkQZDZD`,
          },
          body: JSON.stringify({
            data: [fbPayload],
          }),
        })
          .then((fbResponse) => fbResponse.json())
          .then((fbResponseJson) => {
            console.log("Facebook Conversion API response:", fbResponseJson);
            if (!fbResponseJson.events_received) {
              console.error("Error sending event to Facebook:", fbResponseJson);
            }
          })
          .catch((error) => {
            console.error("Failed to send event to Facebook:", error);
          });

        localStorage.setItem("orderId", response.data.order._id);
        if (dataComingFrom === "buy-now") {
          // Remove the purchased item from cart
          localStorage.removeItem("appliedCoupons");
          const updatedCart = cartItems.filter(
            (cartItem) => !items.some((item) => item._id === cartItem._id)
          );

          items.forEach((item) => {
            dispatch(cartActions.removeFromCart(item));
          });
        } else {
          // Clear the entire cart
          localStorage.removeItem("appliedCoupons");
          localStorage.removeItem("cart");
        }

        localStorage.removeItem("order");

        setTimeout(() => {
          window.location.href = "/order-success";
        }, 300);
      } else {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 300);
      }
    } catch (error) {
      setTimeout(() => {
        window.location.href = "/cart";
      }, 300);
    }
  };

  // we will be filling this object in next step.
  const options = {
    key: keyId, // key id from props
    amount, // Amount in lowest denomination from props
    currency, // Currency from props.
    name: "D'decor Rugs", // Title for your organization to display in checkout modal
    // image, // custom logo  url
    order_id: orderId, // order id from props
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      console.log("succeeded");
      console.log(response);
      paymentId.current = response.razorpay_payment_id;
      const razorpayPaymentId = response.razorpay_payment_id;
      const razorpayOrderId = response.razorpay_order_id;
      const razorpaySignature = response.razorpay_signature;
      // Most important step to capture and authorize the payment. This can be done of Backend server.

      const succeeded =
        CryptoJS.HmacSHA256(
          `${response.razorpay_order_id}|${response.razorpay_payment_id}`,
          keySecret
        ).toString() === response.razorpay_signature;

      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: razorpayPaymentId,
            currency: currency,
            value: amount,
            items: items.map((item) => ({
              item_id: item._id,
              item_name: item.name,
              price: item.priceSale ?? item.price,
              quantity: item.quantity,
              item_variant: item.sizes.length !== 0 ? item.sizes.name : "",
            })),
          },
        });
        handlePayment(
          "succeeded",
          razorpayOrderId,
          razorpayPaymentId,
          razorpaySignature
        );
      } else {
        handlePayment("failed", razorpayOrderId, razorpayPaymentId);
      }
    },
    modal: {
      confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason) => {
        const {
          reason: paymentReason,
          field,
          step,
          code,
        } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
        if (reason === undefined) {
          console.log("cancelled");
          handlePayment("Cancelled");
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === "timeout") {
          console.log("timedout");
          handlePayment("timedout");
        }
        // Reason 3 - When payment gets failed.
        else {
          console.log("failed");
          handlePayment("failed", {
            paymentReason,
            field,
            step,
            code,
          });
        }
      },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default.
    retry: {
      enabled: false,
    },
    timeout: 900, // Time limit in Seconds
    theme: {
      color: "", // Custom color for your checkout modal.
    },
  };

  useEffect(() => {
    console.log("in razorpay");
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
