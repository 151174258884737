import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Checkout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [address, setAddress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch login status and address from localStorage
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    const storedAddress = JSON.parse(localStorage.getItem("address") || "{}");
    localStorage.setItem("dataComingFrom", "buy-now");
    setIsLoggedIn(loggedInStatus);
    setAddress(storedAddress);
    const isAddressEmpty = (address) => {
      return !address || Object.keys(address).length === 0;
    };
    // Redirect logic
    if (loggedInStatus && !isAddressEmpty(storedAddress)) {
      navigate("/payment"); // Redirect to payment if logged in and address exists
    } else if (loggedInStatus) {
      navigate("/checkoutAddress"); // Redirect to address page if logged in but no address
    } else {
      navigate("/checkoutLogin"); // Redirect to login if not logged in
    }
  }, [navigate]);

  return (
    <Fragment>
      <Helmet>
        <title>Checkout | D'decor Rugs</title>
        <meta property="og:title" content="Checkout | D'decor Rugs" />
      </Helmet>
    </Fragment>
  ); // This component is used only for redirection
};

export default Checkout;
