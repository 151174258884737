import React, { Fragment, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/PrivacyPolicy.scss";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleRedirect = (item) => {
    window.location.href = item;
  };

  return (
    <Fragment>
      <Helmet>
        <title>Privacy Policy | D'decor Rugs</title>
        <meta property="og:title" content="Privacy Policy | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="intro-pri-container">
        <div className="heading-pri">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - <span>Privacy Policy</span>
          </h2>
        </div>
      </div>
      <div className="privacy-content">
        <div className="main-Privacy-Head">
          <h2>Privacy Policy</h2>
        </div>

        <h6>Limited User</h6>
        <p>
          The user agrees and undertakes not to reverse engineer, modify, copy,
          distribute, transmit, display, perform, reproduce, publish, license,
          create derivative works from, transfer, or sell any information or
          software obtained from the website.
        </p>
        <p>
          For the removal of doubt, it is clarified that unlimited or wholesale
          reproduction, copying of the content for commercial or non-commercial
          purposes, and unwarranted modification of data and information within
          the content of the Website is not permitted.
        </p>

        <h6>Intellectual Property</h6>
        <p>
          The company and its suppliers and licensors expressly reserve all
          intellectual property rights in all text, programs, products,
          processes, technology, content, and other materials that appear on
          this Site/Mobile app. Access to this Site does not confer and shall
          not be considered as conferring upon anyone any license under any of
          Our or any third party's intellectual property rights.
        </p>
        <p>
          All rights, including copyright, in this website are owned by or
          licensed to the company. Any use of this website or its contents,
          including copying or storing it in whole or part, other than for your
          own personal, non-commercial use, is prohibited without the permission
          of the company. You may not modify, distribute, or re-post anything on
          this website for any purpose.
        </p>
        <p>
          References on this Site to any names, marks, products, or services of
          third parties or hypertext links to third-party sites or information
          are provided solely as a convenience to you and do not in any way
          constitute or imply Our endorsement, sponsorship, or recommendation of
          the third party, information, product, or service. We are not
          responsible for the content of any third-party sites and do not make
          any representations regarding the content or accuracy of material on
          such sites. If you decide to link to any such third-party websites,
          you do so entirely at your own risk.
        </p>
        <p>
          All materials, including images, text, illustrations, designs, icons,
          photographs, programs, music clips or downloads, video clips, and
          written and other materials that are part of this Site (collectively,
          the "Contents"), are intended solely for personal, non-commercial use.
          You may download or copy the Contents and other downloadable materials
          displayed on the Site for your personal use only. No right, title, or
          interest in any downloaded materials or software is transferred to you
          as a result of any such downloading or copying.
        </p>

        <p>
          You may not reproduce (except as noted above), publish, transmit,
          distribute, display, modify, create derivative works from, sell, or
          participate in any sale of or exploit in any way, in whole or in part,
          any of the Contents, the Site, or any related software. All software
          used on this Site is the property of the company or its suppliers and
          is protected by Indian and international copyright laws
        </p>

        <p>
          The Contents and software on this Site may be used only as a shopping
          resource. Any other use, including the reproduction, modification,
          distribution, transmission, republication, display, or performance of
          the Contents on this Site, is strictly prohibited
        </p>
        <h6>User Warranty and Registration</h6>
        <p>
          The User guarantees, warrants, and certifies that You are the owner of
          the content which you submit or otherwise authorized to use the
          content and that the content does not infringe upon the property
          rights, intellectual property rights or other rights of others.
        </p>

        <h6>Indemnity</h6>
        <p>
          You agree to defend, indemnify, and hold harmless the company, its
          employees, directors, officers, agents, and their successors and
          assigns from and against any and all claims, liabilities, damages,
          losses, costs, and expenses, including attorney's fees, caused by or
          arising out of claims based upon your actions or inactions, which may
          result in any loss or liability to Us or any third party, including
          but not limited to breach of any warranties, representations, or
          undertakings, or in relation to the non-fulfillment of any of your
          obligations under these Terms, or arising out of your violation of any
          applicable laws or regulations, including but not limited to
          Intellectual Property Rights, payment of statutory dues and taxes,
          claims of libel, defamation, violation of rights of privacy or
          publicity, loss of service by other subscribers, and infringement of
          intellectual property or other rights. This clause shall survive the
          expiry or termination of these Terms.
        </p>
        <h6>Termination</h6>
        <p>
          We may suspend or terminate your use of the Website or any service if
          we believe, in our sole and absolute discretion, that you have
          breached any of the Terms. We may also, in our sole discretion and at
          any time, discontinue providing the service or any part thereof.
          Termination of your access to the use of the Website may be effected
          without prior notice to you, and you acknowledge and agree that we may
          immediately bar any further access to the Website..
        </p>

        <p>
          If you or we terminate your use of the Website or any service, we may
          delete any content or other materials relating to your use of the
          Website and will have no liability to you or any third party for doing
          so. You shall be liable to pay for any service or product that you
          have already ordered up to the time of termination by either party
          whatsoever.
        </p>

        <h6>Disclaimer of Warranties</h6>
        <p>
          You expressly understand and agree that your access to or use of the
          Website is at your sole risk. The Website is provided on an "as is"
          and "as available" basis. We and our licensors, suppliers, vendors,
          parent, holding, subsidiary, and related companies, affiliates,
          officers, agents, and employees expressly disclaim all warranties of
          any kind, whether express or implied, including, but not limited to,
          the implied warranties of merchantability, fitness for a particular
          purpose, and non-infringement.
        </p>

        <p>
          We, our associates, and technology partners make no representations or
          warranties about the accuracy, reliability, completeness, currentness,
          and/or timeliness of any content, information, software, text,
          graphics, links, or communications provided on or through the use of
          the Site or that the operation of the Site will be error-free and/or
          uninterrupted. Consequently, we assume no liability whatsoever for any
          monetary or other damages suffered by you on account of the delay,
          failure, interruption, or corruption of any data or other information
          transmitted in connection with the use of the Site, and/or any
          interruption or errors in the operation of the Site.
        </p>

        <p>
          We and our licensors, suppliers, vendors, parent, holding, subsidiary,
          and related companies, affiliates, officers, agents, and employees
          make no warranty that the service will meet your requirements, the
          service will be uninterrupted, timely, secure, or error-free, the
          service will be accessible at any time or at all times via the channel
          selected or used by you, or that the information, content, or
          advertisements (collectively, the "materials") contained on,
          distributed through, or linked, downloaded, or accessed from or
          through the service, or the results obtained from the use of the
          service, will be accurate or reliable.
        </p>

        <p>
          This disclaimer does not apply to any product warranty offered by the
          manufacturer of the product as specified in the product
          specifications. This disclaimer constitutes an essential part of the
          Terms.
        </p>

        <h6>Law enforcement</h6>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h6>Limitation of Liability</h6>
        <p>
          To the fullest extent permitted under applicable law, we, our
          associates, parent companies, or suppliers shall not be liable for any
          indirect, incidental, special, incidental, consequential, or exemplary
          damages, including but not limited to damages for loss of profits,
          goodwill, use, data, or other intangible losses arising out of or in
          connection with the Site, its services, or these Terms.
        </p>
        <p>
          Without prejudice to the generality of the section above, our total
          liability to you for all liabilities arising out of these Terms,
          whether in tort or contract, is limited to the value of the product
          ordered by you.
        </p>

        <h6>Website Security</h6>
        <p>
          You are prohibited from violating or attempting to violate the
          security of the Site, including, without limitation Accessing data not
          intended for you or logging onto a server or an account which you are
          not authorized to access Attempting to probe, scan or test the
          vulnerability of a system or network or to breach security or
          authentication measures without proper authorization
        </p>
        <p>
          Attempting to interfere with service to any other user, host or
          network, including, without limitation, via means of submitting a
          virus to the Site, overloading, “flooding”, “spamming”, “mail bombing”
          or “crashing”
        </p>
        <p>
          Sending unsolicited email, including promotions and/or advertising of
          products or services Forging any TCP/IP packet header or any part of
          the header information in any email or newsgroup posting. Violations
          of system or network security may result in civil or criminal
          liability. We will investigate occurrences that may involve such
          violations and may involve, and cooperate with, law enforcement
          authorities in prosecuting users who are involved in such violations.
          You agree not to use any device, software, or routine to interfere or
          attempt to interfere with the proper working of this Site or any
          activity being conducted on this Site. You agree, further, not to use
          or attempt to use any engine, software, tool, agent or other device or
          mechanism (including without limitation browsers, spiders, robots,
          avatars or intelligent agents to navigate or search this Site other
          than the search engine and search agents available from Us on this
          Site and other than generally available third party web browsers
          (e.g., Netscape Navigator, Microsoft Internet Explorer, Google
          Chrome).
        </p>
        <h6>Log Data</h6>
        <p>
          We want to inform you that whenever you use our service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called log data. This log data includes
          information such as your device internet Protocol (“IP”) address,
          device name, operating system version, the configuration of the app
          when utilizing our services, the time, and data of your use of the
          service, and other statistics.
        </p>
        <h6>Fraudulent/Failed Transactions</h6>
        <p>
          We reserve the right to recover the cost of goods, collection charges
          and lawyer fees from persons using the Site fraudulently. We reserve
          the right to initiate legal proceedings against such persons for
          fraudulent use of the Site and any other unlawful acts or acts or
          omissions in breach of these terms and conditions.
        </p>

        <h6>Entire Agreement</h6>
        <p>
          These Terms and Conditions of Use constitute the entire agreement
          between you and the company and govern your use of the Website,
          superseding any prior understandings and agreements between you and us
          and any previous statements or representations from either party to
          the other party. These Terms do not apply to any affiliate services,
          third-party content or third- party software that does not or cannot
          reasonably be deemed to form part of the Website which may be provided
          to you by our licensors, suppliers, vendors, parent, holding,
          subsidiary or related companies, other affiliates or other third
          parties, which may be subject to additional terms and conditions
          imposed by that party. You also may be subject to additional terms and
          conditions that may apply when you use affiliate services, third-party
          content, or third-party software.
        </p>

        <h6>Non-Transferability</h6>
        <p>
          You agree that your account on the Website is non-transferable and any
          rights to your Account ID or contents within your account terminate
          upon your death. Upon receipt of a copy of a death certificate, your
          account may be terminated, and all contents therein permanently
          deleted.
        </p>
        <h6>Governing Law and Jurisdiction</h6>
        <p>
          These Terms and the relationship between you and us shall be governed
          by the laws of the Republic of India without regard to its conflict of
          law provisions. The Courts at Mumbai, India shall have exclusive
          jurisdiction in any proceedings arising out of these Terms.
        </p>
        <h6>Change of this Privacy Policy</h6>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes by posting
          their new Privacy policy on this page. These changes are effective
          immediately after they are posted on this page.
        </p>
        <h6>Contact Us</h6>
        <p>
          Please send any questions or comments (including all inquiries
          unrelated to copyright infringement) regarding this website to
          enquiry@sansaar.co.in
        </p>
        <p>
          In accordance with Information Technology Act 2000 and rules made
          there under, the name and contact details of the Grievance Officer are
          provided below
        </p>

        <div className="privacy-policy-contact-details">
          <h5>Grievance Officer</h5>
          <p>
            1081/1082, Solitaire Corporate Park - 10
            <br />
            167, Guru Hargovindji Marg
            <br />
            Andheri East, Mumbai 400093
            <br />
            INDIA
          </p>
          <p>
            Mail id:{" "}
            <a href="mailto:support@ddecorrugs.com" className="email-link">
              support@ddecorrugs.com
            </a>{" "}
          </p>
          <p>Telephone: 7718801634</p>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
