import React, { Fragment, useEffect, useState } from "react";
import Blog1 from "../../assets/images/Rectangle 6741.png";
import blogimage1 from "../../assets/images/blogimage1.jpg";
import blogimage2 from "../../assets/images/blogimage2.jpg";
import newblogimage3 from "../../assets/images/newblogimage3.jpg";
import newblogimage1 from "../../assets/images/newblogimage1.jpg";
import Logo from "../../assets/images/logo.png";
import "../../assets/css/Blog.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Helmet } from "react-helmet";
import { fetchBlogs } from "../../utils";
import moment from "moment";
// const blogs = [
//   {
//     imgSrc: blogimage1,
//     date: "July, 2024",
//     category: "Lorem Ipsum - Lore - Lorem",
//     title: "Unleash Your Style: Statement Living with Abstract Rugs",
//     slug: "blog-inner",
//   },
//   {
//     imgSrc: blogimage2,
//     date: "Sept, 2024",
//     category: "Lorem Ipsum - Lore - Lorem",
//     title: "How to Choose the Perfect Rug for Every Room",
//     slug: "blog-inner-two",
//   },
//   {
//     imgSrc: newblogimage1,
//     date: "Nov, 2024",
//     category: "Lorem Ipsum - Lore - Lorem",
//     title: "The Art of Choosing Colors: Perfectly Pairing Your Rug with Décor",
//     slug: "blog-inner-three",
//   },
// ];
const formatBlogDate = (dateString) => {
  return moment(dateString).format("MMM, YYYY"); // Format as "Nov, 2024"
};

export const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  function handleRedirect(slug) {
    window.location.href = `blog-inner/${slug}`;
  }
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true);
        const response = await fetchBlogs();

        setBlogs(response);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Blogs | D'decor Rugs</title>
        <meta property="og:title" content="Blogs | D'decor Rugs" />
      </Helmet>
      {loading ? (
        <div className="loading-component">
          <img src={Logo} alt="" />
        </div>
      ) : (
        <Fragment>
          <Header />
          <div className="intro-blog-container">
            <div className="heading-blog">
              <h2>
                <span
                  onClick={() => handleRedirect("/")}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </span>{" "}
                - <span>Blogs</span>
              </h2>
            </div>
          </div>

          <div className="blog-main-container">
            {blogs.map((blog, index) => (
              <div
                key={index}
                className="blog-box"
                onClick={() => handleRedirect(blog.slug)}
                style={{ cursor: "pointer" }}
              >
                <div className="blog-img">
                  <div className="blog-image-main">
                    <img src={blog.cover ? blog.cover.url : ""} alt="" />
                  </div>
                  <p>{formatBlogDate(blog.blogDate)}</p>
                </div>
                <div className="blog-content">
                  <h3>{blog.title}</h3>
                  <p className="truncate">{blog.metaDescription}</p>

                  <a href={`blog-inner/${blog.slug}`} className="read-more">
                    READ MORE
                  </a>
                </div>
              </div>
            ))}
          </div>
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};
