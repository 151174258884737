import React, { Fragment, useEffect, useState, useRef } from "react";
import ProductCard from "../../Common/ProductCard";
import { RxCross1 } from "react-icons/rx";
import "../../../assets/css/FilterListMob.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
const Products = ({
  products,
  count,
  filterValues,
  showViewCart,
  handleViewCart,
  handleLoadMore,
  sizes,
  colors,
  rooms,
  patterns,
  shapes,
  collections,
  categories,
  filterParams,
  updateFilters,
  loading,
}) => {
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isColorListOpen, setIsColorListOpen] = useState(false);
  const [isCategoryListOpen, setIsCategoryListOpen] = useState(false);
  const [isSizeListOpen, setIsSizeListOpen] = useState(false);
  const [isRoomListOpen, setIsRoomListOpen] = useState(false);
  const [isPatternListOpen, setIsPatternListOpen] = useState(false);
  const [isShapeListOpen, setIsShapeListOpen] = useState(false);
  const [isCollectionListOpen, setIsCollectionListOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("Sort by");
  // const [isPriceRangeListOpen, setIsPriceRangeListOpen] = useState(false);
  const menuRef = useRef(null);
  const observerRef = useRef(null);
  const [isPriceRangeListOpen, setIsPriceRangeListOpen] = useState(false);
  const toggleColorList = () => setIsColorListOpen(!isColorListOpen);
  const toggleCategoryList = () => setIsCategoryListOpen(!isCategoryListOpen);
  const toggleSizeList = () => setIsSizeListOpen(!isSizeListOpen);
  const toggleRoomList = () => setIsRoomListOpen(!isRoomListOpen);
  const togglePatternList = () => setIsPatternListOpen(!isPatternListOpen);
  const toggleShapeList = () => setIsShapeListOpen(!isShapeListOpen);
  const toggleCollectionList = () =>
    setIsCollectionListOpen(!isCollectionListOpen);
  const togglePriceRangeList = () =>
    setIsPriceRangeListOpen(!isPriceRangeListOpen);
  const sortOptions = [
    // { name: "Sort by", slug: "" },
    { name: "Newest", slug: "newest" },
    { name: "Popular", slug: "popular" },
    { name: "Low to High", slug: "low_to_high" },
    { name: "High to Low", slug: "high_to_low" },
  ];
  const toggleSortDropdown = () => setIsSortDropdownOpen(!isSortDropdownOpen);
  useEffect(() => {
    // Fetch the first filter from localStorage
    const storedFilter = localStorage.getItem("selectedItem");
    if (storedFilter) {
      setSelectedFilter(JSON.parse(storedFilter));
    }
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sortParam = searchParams.get("sort");

    if (sortParam) {
      const matchedOption = sortOptions.find(
        (option) => option.slug === sortParam
      );
      if (matchedOption) {
        setSelectedSortOption(matchedOption.name);
      }
    }
  }, [sortOptions]);
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState); // Toggle menu visibility
  };
  const handleCancelFilter = () => {
    localStorage.removeItem("selectedItem");
    setSelectedFilter(null);
    window.location.href = "/rugs";
  };
  const handleFilterChange = (key, value) => {
    let updatedFilters = { ...filterParams };

    const currentValues = updatedFilters[key]
      ? updatedFilters[key].split("_")
      : [];

    if (currentValues.includes(value)) {
      const newValues = currentValues.filter((v) => v !== value);
      if (newValues.length > 0) {
        updatedFilters[key] = newValues.join("_");
      } else {
        delete updatedFilters[key];
      }
    } else {
      updatedFilters[key] = [...currentValues, value].join("_");
    }

    // Update the filters
    updateFilters(updatedFilters);
  };
  const handleSortChange = (slug, name) => {
    const updatedFilters = { ...filterParams };
    if (slug) {
      // Add sort to filters if slug exists
      updatedFilters.sort = slug;
    } else {
      // Remove sort from filters if slug is null
      delete updatedFilters.sort;
    }

    updateFilters(updatedFilters);

    // Update the URL without refreshing the page
    const searchParams = new URLSearchParams(updatedFilters).toString();
    window.history.pushState({}, "", `?${searchParams}`);

    setSelectedSortOption(name); // Set the selected option name
    setIsSortDropdownOpen(false); // Close the dropdown
  };
  const isFilterSelected = (key, value) => {
    return filterParams[key] && filterParams[key].split("_").includes(value);
  };
  // console.log(selectedFilter);

  const priceListData = [
    "Under - ₹20,000",
    "₹20,000 - ₹50,000",
    "₹50,000 - ₹100,000",
    "₹100,000 - ₹200,000",
    "₹200,000 - ₹300,000",
    "Above - ₹300,000",
  ];
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading) {
      handleLoadMore();
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [loading]);
  const [gridLayout, setGridLayout] = useState("four"); // Default is 4 columns
  const [activeColor, setActiveColor] = useState("none");
  // When grey box is clicked, set grid to 3 columns
  const handleGreyBoxClick = () => {
    setGridLayout("three");
    setActiveColor("grey");
  };

  // When black box is clicked, set grid to 4 columns
  const handleBlackBoxClick = () => {
    setGridLayout("four");
    setActiveColor("black");
  };
  return (
    <Fragment>
      <div className="abstract-right-box">
        <div className="abstract-right-box-content">
          <div className="heading-text-and-color-box">
            <div className="heading-items">
              <h3>
                {" "}
                {selectedFilter
                  ? `${selectedFilter.name
                      .toLowerCase()
                      .replace(/\b\w/g, (char) => char.toUpperCase())}`
                  : "All Rugs"}
              </h3>
              <p>
                {count} {count === 1 ? "item" : "items"}{" "}
              </p>
            </div>
            <div className="sort-button-menu-icon">
              <div className="color-and-filter">
                {/* Color Boxes */}
                <div className="colors-box">
                  {/* Grey Color Group */}
                  <div className="3cols-div">
                    <div
                      className={`grey-color ${
                        activeColor === "grey"
                          ? "active"
                          : activeColor === "none"
                          ? "default"
                          : "inactive"
                      }`}
                      onClick={handleGreyBoxClick}
                    >
                      {[...Array(3)].map((_, index) => (
                        <div key={index} className="grey-box"></div>
                      ))}
                    </div>
                  </div>

                  {/* Black Color Group */}
                  <div className="cols-div">
                    <div
                      className={`black-color ${
                        activeColor === "black"
                          ? "active"
                          : activeColor === "none"
                          ? "default"
                          : "inactive"
                      }`}
                      onClick={handleBlackBoxClick}
                    >
                      {[...Array(4)].map((_, index) => (
                        <div key={index} className="black-box"></div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="filter-box">
                  <div className="sort-button" onClick={toggleSortDropdown}>
                    <button>
                      {selectedSortOption} &nbsp;
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.3273 0.632768C13.3273 0.754551 13.2789 0.871346 13.1928 0.95746C13.1067 1.04357 12.9899 1.09195 12.8681 1.09195H0.623246C0.501463 1.09195 0.384668 1.04357 0.298554 0.95746C0.212441 0.871346 0.164062 0.754551 0.164062 0.632768C0.164062 0.510985 0.212441 0.394189 0.298554 0.308076C0.384668 0.221962 0.501463 0.173584 0.623246 0.173584H12.8681C12.9899 0.173584 13.1067 0.221962 13.1928 0.308076C13.2789 0.394189 13.3273 0.510985 13.3273 0.632768ZM11.4906 3.69399C11.4906 3.81578 11.4422 3.93257 11.3561 4.01868C11.27 4.1048 11.1532 4.15318 11.0314 4.15318H2.45998C2.3382 4.15318 2.2214 4.1048 2.13529 4.01868C2.04918 3.93257 2.0008 3.81578 2.0008 3.69399C2.0008 3.57221 2.04918 3.45541 2.13529 3.3693C2.2214 3.28319 2.3382 3.23481 2.45998 3.23481H11.0314C11.1532 3.23481 11.27 3.28319 11.3561 3.3693C11.4422 3.45541 11.4906 3.57221 11.4906 3.69399ZM9.65386 6.75522C9.65386 6.877 9.60548 6.9938 9.51937 7.07991C9.43325 7.16602 9.31646 7.2144 9.19467 7.2144H4.29672C4.17493 7.2144 4.05814 7.16602 3.97202 7.07991C3.88591 6.9938 3.83753 6.877 3.83753 6.75522C3.83753 6.63343 3.88591 6.51664 3.97202 6.43052C4.05814 6.34441 4.17493 6.29603 4.29672 6.29603H9.19467C9.31646 6.29603 9.43325 6.34441 9.51937 6.43052C9.60548 6.51664 9.65386 6.63343 9.65386 6.75522Z"
                          fill="#595959"
                        />
                      </svg>
                    </button>
                    {isSortDropdownOpen && (
                      <ul className="sort-dropdown">
                        {sortOptions.map((option) => (
                          <li
                            key={option.slug}
                            onClick={() =>
                              handleSortChange(option.slug, option.name)
                            }
                          >
                            {option.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="menu-icon-rugs-in-mobile-view"
                onClick={toggleMenu}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.75 12.5C4.16421 12.5 4.5 12.1642 4.5 11.75C4.5 11.3358 4.16421 11 3.75 11C3.33579 11 3 11.3358 3 11.75C3 12.1642 3.33579 12.5 3.75 12.5Z"
                    fill="#111111"
                  />
                  <path
                    d="M20.06 11H7.94C7.42085 11 7 11.3156 7 11.705V11.795C7 12.1844 7.42085 12.5 7.94 12.5H20.06C20.5791 12.5 21 12.1844 21 11.795V11.705C21 11.3156 20.5791 11 20.06 11Z"
                    fill="#111111"
                  />
                  <path
                    d="M20.06 16H3.94C3.42085 16 3 16.3156 3 16.705V16.795C3 17.1844 3.42085 17.5 3.94 17.5H20.06C20.5791 17.5 21 17.1844 21 16.795V16.705C21 16.3156 20.5791 16 20.06 16Z"
                    fill="#111111"
                  />
                  <path
                    d="M20.06 6H3.94C3.42085 6 3 6.31564 3 6.705V6.795C3 7.18436 3.42085 7.5 3.94 7.5H20.06C20.5791 7.5 21 7.18436 21 6.795V6.705C21 6.31564 20.5791 6 20.06 6Z"
                    fill="#111111"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="selected-all-filter">
          {selectedFilter && (
            <div className="selected-item">
              <span>
                {selectedFilter.name
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
              </span>
              <RxCross1 onClick={handleCancelFilter} />
            </div>
          )}

          {/* {filterValues.map((filter, index) => (
            <div className="selected-item" key={index}>
              <span>{filter}</span>
              <RxCross1 />
            </div>
          ))} */}
        </div>

        {/* <div className="abstract-all-cards">
          <ProductCard
            products={products}
            showViewCart={showViewCart}
            handleViewCart={handleViewCart}
          />
        </div> */}
        <div
          className={`abstract-all-cards ${
            gridLayout === "three" ? "three-columns" : "four-columns"
          }`}
        >
          <ProductCard
            products={products}
            showViewCart={showViewCart}
            handleViewCart={handleViewCart}
          />
        </div>
        {products.length < count && (
          <div ref={observerRef} className="loading-trigger">
            {loading && <p>Loading...</p>}
          </div>
        )}
        <div ref={menuRef} className={`menu-box ${isMenuOpen ? "open" : ""}`}>
          {isMenuOpen && (
            <div className="shop-by-color-price-size">
              <div className="shopby-crossicon">
                <h4>Shop by</h4>
                <RxCross1 onClick={closeMenu} style={{ color: "#595959" }} />
              </div>
              {/* Category */}
              <div className="list">
                <div className="list-header" onClick={toggleCategoryList}>
                  <p>Category</p>
                  <span>
                    {isCategoryListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {isCategoryListOpen && (
                  <ul>
                    {categories.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("category", item.slug)}
                          onChange={() =>
                            handleFilterChange("category", item.slug)
                          }
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Size */}
              <div className="list">
                <div className="list-header" onClick={toggleSizeList}>
                  <p>Size</p>
                  <span>
                    {isSizeListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {isSizeListOpen && (
                  <ul>
                    {sizes.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("size", item.slug)}
                          onChange={() => handleFilterChange("size", item.slug)}
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Color */}
              <div className="list">
                <div className="list-header" onClick={toggleColorList}>
                  <p>Color</p>
                  <span>
                    {isColorListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {isColorListOpen && (
                  <ul>
                    {colors.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("color", item.slug)}
                          onChange={() =>
                            handleFilterChange("color", item.slug)
                          }
                        />
                        <label>
                          <span
                            className="colorCircle"
                            style={{
                              backgroundColor: item.colorHexCode,
                              display: "inline-block",
                              width: "15px",
                              height: "15px",
                              borderRadius: "50%",
                              marginRight: "10px",
                            }}
                          ></span>
                          {item.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Rooms */}
              <div className="list">
                <div className="list-header" onClick={toggleRoomList}>
                  <p>Rooms</p>
                  <span>
                    {isRoomListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {isRoomListOpen && (
                  <ul>
                    {rooms.map((item) => (
                      <li key={item.name}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("room", item.slug)}
                          onChange={() => handleFilterChange("room", item.slug)}
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Patterns */}
              <div className="list">
                <div className="list-header" onClick={togglePatternList}>
                  <p>Patterns</p>
                  <span>
                    {isPatternListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {isPatternListOpen && (
                  <ul>
                    {patterns.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("pattern", item.slug)}
                          onChange={() =>
                            handleFilterChange("pattern", item.slug)
                          }
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Shapes */}
              <div className="list">
                <div className="list-header" onClick={toggleShapeList}>
                  <p>Shapes</p>
                  <span>
                    {isShapeListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {isShapeListOpen && (
                  <ul>
                    {shapes.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("shape", item.slug)}
                          onChange={() =>
                            handleFilterChange("shape", item.slug)
                          }
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Collection */}
              <div className="list">
                <div className="list-header" onClick={toggleCollectionList}>
                  <p>Collection</p>
                  <span>
                    {isCollectionListOpen ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </span>
                </div>
                {isCollectionListOpen && (
                  <ul>
                    {collections.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("collection", item.slug)}
                          onChange={() =>
                            handleFilterChange("collection", item.slug)
                          }
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Price Range */}
              <div className="list">
                <div className="list-header" onClick={togglePriceRangeList}>
                  <p>Price Range</p>
                  <span>
                    {isPriceRangeListOpen ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </span>
                </div>

                {isPriceRangeListOpen && (
                  <div>
                    <ul>
                      {priceListData.map((item, index) => (
                        <li key={index}>
                          <input type="checkbox" />
                          <label>{item}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Products;
