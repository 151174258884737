import React, { Fragment, useState, useEffect } from "react";
import "../../assets/css/OrderSummary.scss";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { baseURL, formatPrice } from "../../utils";
const OrderSummary = ({ order, email, totalPrice }) => {
  const [code, setCode] = useState("");
  const [previousCode, setPreviousCode] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const paymentData = [
    { name: "EMI from ₹6386/month", plan: "View plans" },
    { name: "Save up to ₹100", plan: "View offers" },
  ];
  useEffect(() => {
    const appliedCoupons =
      JSON.parse(localStorage.getItem("appliedCoupons")) || {};

    // Check if there is a coupon for any order
    const appliedCoupon = order.some((item) => appliedCoupons[item._id]);
    if (appliedCoupon) {
      const appliedCoupon = appliedCoupons[order[0]._id];

      setPreviousCode(appliedCoupon.code);
      setCouponData(appliedCoupons[order[0]._id]); // Assuming the same coupon applies to all orders
    }
  }, [order]);
  const handleBuyNow = (e) => {
    e.stopPropagation();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "INR",
        items: order.map((item) => ({
          item_name: item.name,
          item_id: item.sku,
          price: item.priceSale ?? item.price,
          quantity: item.quantity,
          item_variant: item.sizes.length !== 0 ? item.sizes.name : "",
        })),
        value: applyDiscount(totalPrice),
        coupon: previousCode || null, // Add coupon code if available
      },
    });
    console.log(window.dataLayer);
    navigate("/product-checkout");
  };
  const handleBackToShop = (e) => {
    localStorage.removeItem("selectedItem");
    window.location.href = "/rugs";
  };
  const applyDiscount = (total) => {
    if (couponData) {
      const { type, discount } = couponData;
      if (type === "percent") {
        return total - (total * discount) / 100;
      } else if (type === "fixed") {
        return total - discount;
      }
    }
    return total;
  };
  const totalOrderPrice = order.reduce((total, item) => {
    const itemPrice = item.priceSale ?? item.price; // Use priceSale if available
    return total + itemPrice * item.quantity;
  }, 0);
  const handleCouponCode = async () => {
    const appliedCoupons =
      JSON.parse(localStorage.getItem("appliedCoupons")) || {};

    const couponAlreadyApplied = order.some((item) => appliedCoupons[item._id]);
    if (couponAlreadyApplied) {
      const message =
        order.length === 1
          ? "Coupon code already applied for this Rug."
          : "Coupon already applied for one of the Rugs.";
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    try {
      const body = {
        email: email,
      };
      const response = await axios.post(
        `${baseURL}/coupon-codes/${code}`,
        body
      );
      if (response.data.success === true) {
        const coupon = response.data.data;
        setCouponData(coupon);
        order.forEach((item) => {
          appliedCoupons[item._id] = coupon; // Store coupon with each order's ID
        });
        localStorage.setItem("appliedCoupons", JSON.stringify(appliedCoupons));
        setSnackbarMessage("Coupon applied successfully!");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("Error applying coupon.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setCode(e.target.value);
    setPreviousCode(e.target.value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleApplyClick = () => {
    handleCouponCode();
  };
  const handleProductRedirect = (e, item) => {
    e.stopPropagation();
    navigate(`/product/${item.slug}?size=${item.sizes.slug}`);
  };
  return (
    <Fragment>
      <div className="order-summary">
        <div className="coupenInput">
          <input
            type="text"
            placeholder="Enter coupon code"
            value={code}
            onChange={handleInputChange}
          />
          <button onClick={handleApplyClick}>APPLY</button>
        </div>
        <div className="order-card">
          <div className="order-summary">
            <h5>Order Summary</h5>
          </div>
          {order.map((order) => (
            <div
              className="order-details-cart"
              key={order.sku}
              onClick={(e) => handleProductRedirect(e, order)}
            >
              <div className="order-image">
                <img src={order.images[0].url} />
              </div>
              <div className="order-details-payment">
                <div className="order-name-price">
                  <h6>
                    {order.name
                      .toLowerCase()
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </h6>
                  {/* <p>{order.price}</p> */}
                  <div className="orderprice">
                    <p>
                      {" "}
                      {formatPrice(
                        (order.priceSale ?? order.price) * order.quantity
                      )}
                    </p>
                  </div>
                </div>
                <div className="order-size-text-and-color">
                  <p>Color: {order.colors[0].name}</p>
                  <p>Size: {order.sizes.name}</p>
                  <p>{order.sku}</p>
                </div>
                <div className="delivery-text">
                  <p>Estimated delivery in 3 to 5 business days</p>
                </div>
              </div>
            </div>
          ))}
          {order.length !== 0 && <div className="divider"></div>}
          <div className="total-order-price">
            <h5>TOTAL</h5>
            <p>₹ {applyDiscount(totalPrice).toFixed(2)}</p>
          </div>
          {/* {paymentData.map((item, index) => (
            <div className="paymentoption" key={index}>
              <div className="plan-name">
                <h5>{item.name}</h5>
              </div>
              <div className="view-plan">
                <p>& more</p>
                <h6>{item.plan}</h6>
              </div>
            </div>
          ))} */}
          <div className="order-button">
            <button
              className="checkout-button"
              onClick={(e) => handleBuyNow(e)}
            >
              PROCEED TO CHECKOUT
            </button>
            <button
              className="shop-button"
              onClick={(e) => handleBackToShop(e)}
            >
              BACK TO SHOPPING
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default OrderSummary;
