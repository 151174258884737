import React, { Fragment, useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchProducts } from "../../utils";
import MainProduct from "../Sections/Products/MainProduct";
import { fetchShopData } from "../../store/shop-actions";
import { fetchCollections } from "../../store/collection-actions";
import { fetchCategories } from "../../store/category-actions";
import Logo from "../../assets/images/logo.png";
const Product = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [range, setRange] = useState({});
  const shopData = useSelector((state) => state.shops.shopData);
  const collections = useSelector((state) => state.collections.collections);
  const categories = useSelector((state) => state.categories.categories);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const limit = 12; // Number of products to load per page
  const sizes = shopData.sizes || [];
  const colors = shopData.colors || [];
  const rooms = shopData.rooms || [];
  const shapes = shopData.shapes || [];
  const patterns = shopData.patterns || [];

  // Extract query parameters
  const urlParams = new URLSearchParams(location.search);
  const filterParams = Object.fromEntries(urlParams.entries());
  const displayValues = location.state;
  const firstPathValue = location.pathname.split("/")[1]; // Extract the first path value

  useEffect(() => {
    dispatch(fetchShopData());
    dispatch(fetchCollections());
    dispatch(fetchCategories());
  }, [dispatch]);
  useEffect(() => {
    const params = { ...filterParams, page, limit }; // Include page and limit in params
    fetchProducts(firstPathValue, params)
      .then((result) => {
        if (page === 1) {
          setFilteredProducts(result.products); // Initial load or filter change
        } else {
          setFilteredProducts((prevProducts) => [
            ...prevProducts,
            ...result.products,
          ]); // Append new data on "Load More"
        }
        setProductCount(result.count);
        setRange(result.range);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setLoading(false); // Ensure loading is false even if there's an error
      });
  }, [location.search, firstPathValue, page]);

  const updateFilters = (newFilters) => {
    const searchParams = new URLSearchParams(newFilters).toString();
    navigate(`?${searchParams}`);
    setPage(1);
  };
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1); // Increase the page number to load the next set of products
  };

  return (
    <Fragment>
      {loading ? (
        <div className="loading-component">
          <img src={Logo} alt="" />
        </div>
      ) : (
        <>
          <Header />
          <MainProduct
            sizes={sizes}
            colors={colors}
            rooms={rooms}
            shapes={shapes}
            patterns={patterns}
            collections={collections}
            categories={categories}
            filterParams={filterParams}
            updateFilters={updateFilters}
            filteredProducts={filteredProducts}
            count={productCount}
            filterValues={displayValues}
            range={range}
            handleLoadMore={handleLoadMore}
            loading={loading}
          />
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Product;
