import React, { useEffect, useState } from "react";
import { PiWarningCircle } from "react-icons/pi";
import "../../../assets/css/ProfileOrder.scss";
import carttwo from "../../../assets/images/carttwo.png";
import { fetchOrders, formatPrice } from "../../../utils";

const ProfileOrder = () => {
  const [orders, setOrders] = useState([]);
  const token = localStorage.getItem("token"); // Fetch token from localStorage

  useEffect(() => {
    const getOrders = async () => {
      if (token) {
        try {
          const response = await fetchOrders(token);

          setOrders(response.data); // Adjust based on response structure
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    };
    getOrders();
  }, [token]);
  const handleTrackRedirect = () => {
    window.location.href = "/track-order";
  };
  return (
    <div className="profile-order-page-container">
      <div className="profile-order-main-content">
        {orders.length === 0 ? (
          <div className="not-order-present">
            <PiWarningCircle />
            <p>No orders have been placed yet.</p>
          </div>
        ) : (
          orders?.map((order) => (
            <div className="card-of-order" key={order._id}>
              {order.items.map((item) => (
                <div className="innerPart-of-order">
                  <div className="order-card-image">
                    <img src={item?.imageUrl} alt="Order Image" />
                  </div>
                  <div className="order-card-details">
                    <div className="order-name-price">
                      <h6>
                        {item.name
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase()) ||
                          "Order Name"}
                      </h6>
                      <p>{formatPrice(item.price) || "0"}</p>
                    </div>
                    {/* <p className="order-text-p">
     {order.description || "Order description here."}
   </p> */}
                    <div className="order-color-size-address">
                      <p>Order No: {order.orderNo}</p>
                      <p>Color: {item.colors || "Grey"}</p>
                      <p>Size: {item.size || "5X8 ft"}</p>
                      <p>{item.sku || "Order ID"}</p>
                    </div>
                    <div className="order-card-buttons desktop-view-button">
                      <button className="cancel-button">PAID</button>
                      <button
                        className="save-button"
                        onClick={handleTrackRedirect}
                      >
                        TRACK MY ORDER
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="order-card-buttons mobile-view-button">
                <button className="cancel-button">ORDER DETAILS</button>
                <button className="save-button">TRACK MY ORDER</button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProfileOrder;
