import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Checkout.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaFacebook, FaApple } from "react-icons/fa";
import { AiFillGoogleCircle } from "react-icons/ai";
import cartone from "../../assets/images/cart1.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { baseURL } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth-actions";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import * as Yup from "yup";
import CheckoutOrderSummary from "../Common/CheckoutOrderSummary";
import { orderActions } from "../../store/order";
import { cartActions } from "../../store/cart"; // Import cart actions for adding product to cart
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";
import { Helmet } from "react-helmet";
const CheckoutLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.order);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  // Load saved order from localStorage
  useEffect(() => {
    const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
    if (savedOrder.length > 0) {
      dispatch(orderActions.setOrder(savedOrder));
    }
  }, [dispatch]);

  // Save order to localStorage when orderItems are updated
  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(orderItems));
  }, [orderItems]);

  // Detect back navigation and move items to cart, clear from order

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email address is required"),
    password: Yup.string().required("Password is required"),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/auth/login`, values);
      if (response.data.success === true) {
        const { token, user } = response.data;
        const address = {
          address: user.address,
          city: user.city,
          state: user.state,
          country: user.country,
          zip: user.zip,
        };
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("address", JSON.stringify(address));
        dispatch(login(token, user));
        setSnackbarMessage("Logged In Successful");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        if (user.address) {
          // Redirect to the payment page if address exists
          setTimeout(() => navigate("/payment"), 2000);
        } else {
          // Otherwise, redirect to checkout address page
          setTimeout(() => navigate("/checkoutAddress"), 2000);
        }
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const handleForgetPassword = () => {
    navigate("/forget-password");
  };
  const handleCheckoutGuest = () => {
    localStorage.setItem("dataComingFrom", "buy-now");
    navigate("/guest-checkout");
  };
  return (
    <>
      <Helmet>
        <title>Checkout | D'decor Rugs</title>
        <meta property="og:title" content="Checkout | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="login-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - Checkout
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - Checkout
          </p>
        </div>
        <div className="checkout-login-content">
          <div className="checkout-login-form">
            <h5>LOGIN</h5>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange }) => (
                <Form className="login-form-inputs">
                  {/* <label>Email address </label> */}
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    onChange={(e) => {
                      handleChange(e); // Update Formik values
                      setEmail(e.target.value); // Update the state
                    }}
                    value={values.email}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                  {/* <label>Password *</label> */}
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                  />
                  <span
                    className="password-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                  <div className="terms-condition-text">
                    By proceeding I agree to the 
                    <Link to="/terms-and-conditions">
                      Terms & Conditions
                    </Link>{" "}
                    and <Link to="/privacy-policy">Privacy Policy</Link>
                  </div>
                  <button type="submit" disabled={loading}>
                    {loading ? <Loader /> : "LOGIN"}
                  </button>
                </Form>
              )}
            </Formik>
            <div className="guest-checkout-button">
              <button onClick={handleCheckoutGuest}>checkout as guest</button>
            </div>
            <div className="signup-forget-button">
              <p onClick={handleForgetPassword}>Forgot password ?</p>
            </div>
            <div className="sign-up-with">
              <p>
                or continue with{" "}
                <span onClick={() => navigate("/checkoutSignUp")}>Sign up</span>{" "}
                ?
              </p>
            </div>
            <div className="facebook-google-apple-button">
              <button>
                <AiFillGoogleCircle /> Google
              </button>
              {/* <button>
                <FaApple /> Apple
              </button> */}
              <button>
                <FaFacebook /> Facebook
              </button>
            </div>
          </div>

          <CheckoutOrderSummary order={orderItems} email={email} />
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CheckoutLogin;
