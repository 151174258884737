import React, { useState, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "../../assets/css/BlogInner.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Helmet } from "react-helmet";
import { baseURL } from "../../utils";
import axios from "axios";
import moment from "moment"; // Import moment.js
import SanitizedHTML from "react-sanitized-html";
import Logo from "../../assets/images/logo.png";

const formatBlogDate = (dateString) => {
  return moment(dateString).format("MMM, YYYY"); // Format as "Nov, 2024"
};

const BlogInner = () => {
  const [blogData, setBlogData] = useState({});
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true);
        // Fetch current blog data
        const blogResponse = await axios.get(`${baseURL}/blogs/${slug}`);
        const result = blogResponse.data;
        setBlogData(result.data);

        // Fetch all blogs and filter out the current one
        const allBlogsResponse = await axios.get(`${baseURL}/blogs`);
        const allBlogs = allBlogsResponse.data.data;

        // Filter out the current blog based on slug
        const filteredBlogs = allBlogs.filter((blog) => blog.slug !== slug);
        setRecentPosts(filteredBlogs);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [slug]);

  // Handle navigation when a post is clicked
  const handlePostClick = (slug) => {
    navigate(`/blog-inner/${slug}`);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{blogData.title}</title>
        <meta property="og:title" content={blogData.title} />
      </Helmet>
      {loading ? (
        <div className="loading-component">
          <img src={Logo} alt="" />
        </div>
      ) : (
        <Fragment>
          <Header />
          <div className="intro-bloginner-container">
            <div className="heading-bloginner">
              <h2>
                Home - <span>Blogs</span> -<span>{blogData.title}</span>
              </h2>
            </div>
          </div>

          <div className="blog-content-container">
            <div className="content-container-1">
              <div className="blog-image-box">
                <img src={blogData.cover ? blogData.cover.url : ""} alt="" />
                <p>{formatBlogDate(blogData.blogDate)}</p>
              </div>
              <div className="blog-text">
                <h3>{blogData.title}</h3>
                <div className="fixed-content">
                  <SanitizedHTML
                    allowedAttributes={false}
                    allowedTags={false}
                    html={blogData.content}
                  />
                </div>
              </div>
            </div>

            <div className="content-container-2">
              <h2>Recent Posts</h2>
              {recentPosts.map((post) => (
                <div
                  key={post.slug}
                  className="recent-post-box"
                  onClick={() => handlePostClick(post.slug)} // Pass the slug dynamically
                >
                  <div className="recent-img">
                    <img src={post.cover?.url} alt={post.title} />
                  </div>
                  <div className="recent-content">
                    <h4>{post.title}</h4>
                    <p>{formatBlogDate(post.blogDate)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BlogInner;
