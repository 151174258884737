// import React, { useState } from "react";
// import "../../../assets/css/SubscribePopup.scss";
// import subscribepopup from "../../../assets/images/subscribepopup.png";
// import { RxCross1 } from "react-icons/rx";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import {
//   MdOutlineKeyboardArrowDown,
//   MdOutlineKeyboardArrowUp,
// } from "react-icons/md";
// import * as Yup from "yup";

// // Separate EmailSubscribe component
// const EmailSubscribe = () => {
//   const [isArrowUp, setIsArrowUp] = useState(false);
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const handleMonthSelect = (month) => {
//     setSelectedMonth(month); // Set the selected month
//     setIsArrowUp(false); // Close the dropdown
//   };
//   const toggleArrow = () => {
//     setIsArrowUp(!isArrowUp); // Toggle arrow state
//   };
//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   return (
//     <div className="email-subscribe">
//       <div className="email-input-wrapper">
//         <Field
//           type="text"
//           name="email"
//           placeholder="Email id*"
//           className="email-input"
//         />
//       </div>
//       <ErrorMessage name="email" component="div" className="error-message" />
//       <div className="email-input-wrapper">
//         <Field
//           type="text"
//           readOnly
//           name="month"
//           placeholder="Birthday Month"
//           className="email-input"
//           value={selectedMonth} // Display selected month
//         />
//         <span className="arrow-icon" onClick={toggleArrow}>
//           {isArrowUp ? (
//             <MdOutlineKeyboardArrowUp />
//           ) : (
//             <MdOutlineKeyboardArrowDown />
//           )}
//         </span>
//       </div>
//       {isArrowUp && (
//         <div className="dropdown-content subscribepopup-dropdown">
//           {months.map((month, index) => (
//             <p
//               key={index}
//               className={month === selectedMonth ? "selected" : ""} // Highlight selected month
//               onClick={() => handleMonthSelect(month)}
//             >
//               {month}
//             </p>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// // Main SubscribePopup component
// const SubscribePopup = ({ isVisible, onClose }) => {
//   if (!isVisible) return null; // Don't render if not visible

//   // Yup validation schema
//   const validationSchema = Yup.object({
//     email: Yup.string().email("Invalid email address").required("Required"),
//   });

//   return (
//     <div className="subscribe-popup">
//       <div className="all-the-content-of-popup">
//         <div className="popup-image">
//           <img src={subscribepopup} alt="subscribepopup" />
//         </div>
//         <div className="popup-content">
//           <div className="popup-content-with-image-details">
//             <div className="popup-image-div-with-details">
//               <div className="popup-text-content">
//                 <div className="popup-closs-icon">
//                   <RxCross1 onClick={onClose} />
//                 </div>
//                 <div className="inputs-and-subscribes">
//                   <h4>Rugnovate with D’Decor</h4>
//                   <p>
//                     Be the first to know about our latest arrivals, exclusive
//                     offers, and design tips. Plus, enjoy 10% off your first
//                     purchase when you subscribe!
//                   </p>
//                   <div className="subscribe-form-field">
//                     <Formik
//                       initialValues={{ email: "" }}
//                       validationSchema={validationSchema}
//                       onSubmit={(values) => {
//                         console.log("Form submitted with:", values);
//                       }}
//                     >
//                       {({ handleSubmit }) => (
//                         <Form onSubmit={handleSubmit}>
//                           {/* EmailSubscribe component */}
//                           <EmailSubscribe />
//                           <button type="submit" className="submit-button">
//                             Subscribe
//                           </button>
//                         </Form>
//                       )}
//                     </Formik>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubscribePopup;
import React, { useState, useRef, useEffect } from "react";
import "../../../assets/css/SubscribePopup.scss";
import subscribepopup from "../../../assets/images/subscribepopup.png";
import { RxCross1 } from "react-icons/rx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../../utils";

// Separate EmailSubscribe component
const EmailSubscribe = ({
  handleMonthSelect,
  selectedMonth,
  isArrowUp,
  toggleArrow,
  dropdownRef,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className="email-subscribe">
      <div className="email-input-wrapper">
        <Field
          type="text"
          name="email"
          placeholder="Email id*"
          className="email-input"
        />
      </div>
      <ErrorMessage name="email" component="div" className="error-message" />
      <div className="email-input-wrapper" ref={dropdownRef}>
        <Field
          type="text"
          readOnly
          name="month"
          placeholder="Birthday Month"
          className="email-input"
          value={selectedMonth} // Display selected month
        />
        <span className="arrow-icon" onClick={toggleArrow}>
          {isArrowUp ? (
            <MdOutlineKeyboardArrowUp />
          ) : (
            <MdOutlineKeyboardArrowDown />
          )}
        </span>
      </div>
      {isArrowUp && (
        <div className="dropdown-content-of-subscribe subscribepopup-dropdown">
          {months.map((month, index) => (
            <p
              key={index}
              className={month === selectedMonth ? "selected" : ""}
              onClick={() => handleMonthSelect(month)}
            >
              {month}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

// Main SubscribePopup component
const SubscribePopup = ({ isVisible, onClose }) => {
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsArrowUp(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isArrowUp, setIsArrowUp] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  if (!isVisible) return null;

  const toggleArrow = () => setIsArrowUp(!isArrowUp);
  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    setIsArrowUp(false);
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  const handleSubscribe = async (values, { resetForm }) => {
    setSubscriptionMessage("");
    try {
      const response = await axios.post(`${baseURL}/newsletter`, {
        email: values.email,
        month: selectedMonth,
      });
      const result = response.data;
      if (result.success) {
        setSubscriptionMessage("Thank you for subscribing!");
        setMessageColor("green");
        resetForm();
        setSelectedMonth(""); // Clear selected month
      } else {
        setSubscriptionMessage(result.message || "Something went wrong.");
        setMessageColor("red");
      }
    } catch (error) {
      setSubscriptionMessage("An error occurred. Please try again.");
      setMessageColor("red");
    }
  };

  return (
    <div className="subscribe-popup">
      <div className="all-the-content-of-popup">
        <div className="popup-image">
          <img src={subscribepopup} alt="Subscribe Popup" />
        </div>
        <div className="popup-content">
          <div className="popup-content-with-image-details">
            <div className="popup-image-div-with-details">
              <div className="popup-text-content">
                <div
                  className="popup-close-icon"
                  style={{ textAlign: "right", cursor: "pointer" }}
                >
                  <RxCross1 onClick={onClose} />
                </div>
                <div className="inputs-and-subscribes">
                  <h4>Rugnovate with D’Decor</h4>
                  <p>
                    Be the first to know about our latest arrivals, exclusive
                    offers, and design tips. Plus, enjoy 10% off your first
                    purchase when you subscribe!
                  </p>
                  <div className="subscribe-form-field">
                    <Formik
                      initialValues={{ email: "" }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubscribe}
                    >
                      {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <EmailSubscribe
                            handleMonthSelect={handleMonthSelect}
                            selectedMonth={selectedMonth}
                            isArrowUp={isArrowUp}
                            toggleArrow={toggleArrow}
                          />
                          <button type="submit" className="submit-button">
                            Subscribe
                          </button>
                        </Form>
                      )}
                    </Formik>
                    {subscriptionMessage && (
                      <p
                        className="subscription-message"
                        style={{ color: messageColor }}
                      >
                        {subscriptionMessage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribePopup;
